.customization-tool-container { 
    .central-categories .topBar {
        background-color: #fdd4ad;
        height          : 17px;
    }
    
    .central-categories .titleHeader {
        padding         : 50px 50px 10px 50px;
        background-color: #fef2e6;
    }
    
    .central-categories h1.comming-soon {
        color     : #fdd4ad;
        text-align: center;
        margin    : 3rem;
        text-shadow: 0px 0px 25px black;
    }
    
    .values .topBar {
        background-color: #fcb4cf;
        height          : 17px;
    }
    
    .values .titleHeader {
        padding         : 50px 50px 10px 50px;
        background-color: #fee8f1;
    }
    
    .values h1.comming-soon {
        color     : #fcb4cf;
        text-align: center;
        margin    : 3rem;
    }
    
    .values input.input-round.form-control {
        width: 100%;
    }
    
    .values .p5 {
        padding: 10px 5px 0 5px;
    }
    
    .values .checkIcon {
        display        : flex;
        align-content  : center;
        align-items    : center;
        justify-content: center;
    }
}