.login {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f3f9;
  
    .login-container {
      border: 1px solid #ccc;
      border-radius: 8px;
      padding: 16px;
      background-color: white;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      width: 100%;
      max-width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 25px;
  
      .avatar {
        margin: 10px auto;
        background-color: #4daaff;
      }
      
      .error-message {
        color: rgb(205, 25, 25);
        font-size: 14px;
        margin-top: 8px;
      }
    }
}
  