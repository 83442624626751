/*
    DEMO STYLE
*/

@import "https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;900&display=swap";

span.store-plan-Bronze {
    color: #D0A379;
}

span.store-plan-Silver {
    color: #92ABB2;
}

span.store-plan-Platinum {
    color: #5E5E5E;
}

span.store-plan-span {
    margin-right: 4px;
}

span.store-plan-Gold {
    color: #DAB65B;
}

span.store-plan-FurnitureKiosk {
    color: #5E5E5E;
}

span.store-plan-Brochure {
    color: #2cbc87;
}

p.store-plan {
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 0;
}

body {
    font-family: 'Roboto', sans-serif;
    background: #fafafa;
}

p {
    font-family: 'Roboto', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}
.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

i,
span {
    display: inline-block;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

#sidebar li {
    padding-left: 13px;
}
div#page-content-logos {    
    text-align: right;
}
#web-system {
    max-width: 1300px!important;
    margin-left: 25px;   
    margin-top: 22px;
    margin-bottom: 12px;    
}
li.shopify-title {
    text-align: center;
    color: #83cfec;
    border-top: 1px solid #83cfec;
    padding-top: 15px;
    padding-bottom: 10px;
    font-size: 14px;
    margin-top: 20px;
}
li.furniturepay {
    border-top: 1px solid #83cfec;
    padding-top: 9px;
    padding-bottom: 10px;
    margin-top: 18px;
}
li.active {
    padding-left: 0 !important;
}

.wrapper {
    display: flex;
    align-items: stretch;
}

#sidebar {
    min-width: 218px;
    max-width: 218px;
    background: #7386D5;
    color: #fff;
    transition: all 0.3s;
}

#sidebar.active {
    min-width: 114px !important;
    max-width: 80px;
    text-align: center;
}

#sidebar.active .sidebar-header h3,
#sidebar.active .CTAs {
    display: none;
}

#sidebar.active .sidebar-header strong {
    display: block;
}

#sidebar ul li a {
    text-align: left;
}

#sidebar.active ul li a {
    padding: 20px 10px;
    text-align: center;
    font-size: 0.85em;
}

#sidebar.active ul li a i {
    margin-right: 0;
    display: block;
    font-size: 1.8em;
    margin-bottom: 5px;
}

#sidebar.active ul ul a {
    padding: 10px !important;
}

#sidebar.active .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
        padding-bottom: 26px;
}

#sidebar .sidebar-header {
    padding: 20px;
    background: #6d7fcc;
    
}
#sidebar .sidebar-header strong {
    display: none;
    font-size: 1.8em;
}

#sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #47748b;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}

#sidebar ul li a:hover {
    color: #7386D5;
    background: #fff;
}

#sidebar ul li a i {
    margin-right: 10px;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
    color: #fff;
    background: #6d7fcc;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
}

ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

a.download {
    background: #fff;
    color: #7386D5;
}

a.article,
a.article:hover {
    background: #6d7fcc !important;
    color: #fff !important;
}
img#icon {
    margin-bottom: 6px;
}
/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
}

li.admin-portal-date-utc.col-6 {
    text-align: end;
    align-items: center;
    margin-top: 20px;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */


/*movil*/

@media (max-width: 768px) {
    #sidebar {
        min-width: 80px;
        max-width: 80px;
        text-align: center;
        margin-left: -80px !important;
    }
    .dropdown-toggle::after {
        top: auto;
        bottom: 10px;
        right: 50%;
        -webkit-transform: translateX(50%);
        -ms-transform: translateX(50%);
        transform: translateX(50%);
    }
    #sidebar.active {
        margin-left: 0 !important;
    }
    #sidebar .sidebar-header h3,
    #sidebar .CTAs {
        display: none;
    }
    #sidebar .sidebar-header strong {
        display: block;
    }
    #sidebar ul li a {
        padding: 20px 10px;
    }
    #sidebar ul li a span {
        font-size: 0.85em;
    }
    #sidebar ul li a i {
        margin-right: 0;
        display: block;
    }
    #sidebar ul ul a {
        padding: 10px !important;
    }
    #sidebar ul li a i {
        font-size: 1.3em;
    }
    #sidebar {
        margin-left: 0;
    }
    #sidebarCollapse span {
        display: none;
    }
}