.customization-tool-container {
  .loader-container {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 5em 0;
  }

  $dot-count: 26;
  $dot-size : 10px;
  $dot-space: 15px;
  $dot-start: (
    ($dot-count / 2 + 1) * ($dot-size + $dot-space)) / 2;

  $animation-time : 2s;
  $animation-distance: 25px;

  .loader {
    position: relative;

    .dot {
      animation-name: movement;
      animation-duration: $animation-time;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      height: $dot-size;
      position: absolute;
      top : -#{$dot-size};
      transform : translate3d(0, -#{$animation-distance}, 0) scale(1
    );
    width: $dot-size;

    @for $i from 1 through $dot-count {
      $dot-move: ceil($i / 2);
      $dot-pos : $dot-start - (($dot-size + $dot-space) * $dot-move);

      $animation-delay: -#{$i * .1}s;

      @if $i % 2==0 {
        $animation-delay: -#{($i * .1) + ($animation-time / 2)};
      }

      &:nth-of-type(#{$i}) {
        animation-delay: $animation-delay;
        left: $dot-pos;

        &::before {
          animation-delay: $animation-delay;
        }
      }
    }

    &::before {
      animation-name: size-opacity;
      animation-duration: $animation-time;
      animation-iteration-count: infinite;
      animation-timing-function: ease;
      background: white;
      border-radius: 50%;
      content: '';
      display: block;
      height: 100%;
      width: 100%;
    }

    &:nth-of-type(even)::before {
      background-color: #2dbb7a;
      box-shadow: inset 0 0 4px darken(#2dbb7a, 10%);
    }
  }
}

.top-slider .dot {
  &:nth-of-type(even)::before {
    background-color: #84F8CE;
    box-shadow: inset 0 0 4px darken(#dafdf0, 10%);
  }
}

.welcome .dot {
  &:nth-of-type(even)::before {
    background-color: #07BB7A;
    box-shadow: inset 0 0 4px darken(#dafdf0, 10%);
  }
}

.central-categories .dot {
  &:nth-of-type(even)::before {
    background-color: #fdd4ad;
    box-shadow: inset 0 0 4px darken(#fef2e6, 10%);
  }
}

.values .dot {
  &:nth-of-type(even)::before {
    background-color: #fcb4cf;
    box-shadow: inset 0 0 4px darken(#fee8f1, 10%);
  }
}

.banner-footer .dot {
  &:nth-of-type(even)::before {
    background-color: #c4b6ff;
    box-shadow: inset 0 0 4px darken(#ede9ff, 10%);
  }
}

.banner-landscape .dot {
  &:nth-of-type(even)::before {
    background-color: #f6f0b7;
    box-shadow: inset 0 0 4px darken(#fcfae9, 10%);
  }
}

.categories .dot {
  &:nth-of-type(even)::before {
    background-color: #7dc4e2;
    box-shadow: inset 0 0 4px darken(#d8edf6, 10%);
  }
}

.tagline .dot {
  &:nth-of-type(even)::before {
    background-color: #e99c9c;
    box-shadow: inset 0 0 4px darken(#e6c8c8, 10%);
  }
}

@keyframes movement {
  0% {
    transform: translate3d(0, -#{$animation-distance}, 0);
    z-index: 0;
  }

  50% {
    transform: translate3d(0, #{$animation-distance}, 0);
    z-index: 10;
  }

  100% {
    transform: translate3d(0, -#{$animation-distance}, 0);
    z-index: -5;
  }
}

@keyframes size-opacity {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  25% {
    transform: scale(1.5);
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: .35;
    transform: scale(.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
}