.customization-tool-container {

    .disabled {
        pointer-events: none;
        opacity: 0.5;
    }
    
    .top-slider .topBar {
        background-color: #84F8CE;
        height: 17px;
    }

    .top-slider .titleHeader {
        padding: 50px 50px 10px 50px;
        background-color: #dafdf0;
    }

    .topBar h1.comming-soon {
        color: #84F8CE;
        text-align: center;
        margin: 3rem;
    }

    #imagePreview {
        background-image: url('');
    }

    .addStep h2 {
        text-decoration: underline;
        cursor: pointer;
    }

    #remove-btn {
        margin-left: 1em;
        font-size: 0.9rem;
        color: #f51f1f;
        opacity: 0.8;
        cursor: pointer;
        text-decoration: underline;
        font-weight: 700;
        float: right;
    }
}