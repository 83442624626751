$font: 'Source Sans Pro', sans-serif;

.unauthorized-div {
  background-color: #ff00001d;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  max-width: 300px;
  margin: 0 auto;
  margin-top: 200px;
  margin-bottom: 500px;

  .unauthorized-heading {
    font-size: 20px;
    font-weight: bolder;
    margin-bottom: 10px;
    font-family: $font;
    color: #C74646;
  }

  .unauthorized-text {
    font-size: 16px;
    font-weight: 500;
    font-family: $font;
    color: #C74646;
  }
}

.loading-div {
  margin-top: 180px;
  display: flex;
  justify-content: space-around;
  height: 100vh;

  svg {
    width: 35px;
    height: 35px;
  }
}

.sync-management {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  margin: 0 auto;
  font-family: $font;
  max-width: 650px;
  width: 100vh;

  .sync-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    p {
      font-size: 14px;
      color: #333;
      margin: 0;
      font-weight: bold;
    }

    h1 {
      font-size: 24px;
      color: #333;
      display: flex;
      align-items: center;
      gap: 8px;

      img {
        height: 24px;
        width: 24px;
        filter: grayscale(100%);
      }
    }
  }

  .divider {
    margin: 20px 0;
  }

  .form-container {

    .column {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    hr{
      color: #93a2b6;
    }

    .store-settings-text {
      position: relative;
      width: 100%;
      height: 500px;

      section {
        div {
          .monaco-editor {
            border-radius: 10px;
            .overflow-guard {
              border-radius: 10px;
            }
          }
        }
      }
    }
  }

  .locked-form {
    opacity: 0.5;
    pointer-events: none;


    .column {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 29px;
  }

  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 8px;
    font-weight: 600;
  }

  .error-container {
    text-align: center;
    margin-top: 10px;
  }

  .success-message {
    color: #1a76d2;
    font-size: 15px;
    margin-top: 8px;
    font-weight: 600;
  }

  .edit-button {
    padding: 10px 37px;
    font-size: 14px;
    border: 1px solid #BDCCEA;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    background: #F0F3F9;
    color: rgba(119, 136, 159, 0.87);
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-right: 0px;

    &:hover {
      background-color: #ddd;
    }

    &.disabled {
      cursor: not-allowed;
      background-color: #ccc;
      color: #999;
      border-color: #ccc;

      &:hover {
        background-color: #ccc;
        border-color: #ccc;
      }
    }
  }

  .save {
    background-color: #138EFF;
    color: #fff;
    border-color: #138EFF;

    &:hover {
      background-color: #0d73db;
      border-color: #0d73db;
    }
  }

  .input {
    display: flex;
  }

  .field {
    display: flex;
    align-items: left;
    flex-direction: column;
    margin-bottom: 20px;

    .create-container {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      flex-direction: inherit;
      justify-content: space-between;

      div {
        width: 48%;
      }
    }

    .field-label {
      font-size: 12px;
      font-weight: bold;
      color: #138EFF;
      margin: 0;
    }

    .field-title {
      font-size: 12px;
      font-weight: bolder;
      color: black;
      margin: 0;
    }

    .input-error {
      border: 1px solid #e46a6a;
    }

    .field-edit {
      background-color: white;
      border: 1px solid #CDDBEF;
      border-radius: 5px;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      flex-grow: 1;
      margin-right: 5px;

      .MuiSelect-root {
        font-size: 15px;
        flex-grow: 1;
        padding: 0;
        margin-right: 10px;
        border: none;
        outline: none;

        &::after {
          border-bottom: none;
        }
      }

      input {
        font-size: 15px;
        flex-grow: 1;
        padding: 0;
        margin-right: 10px;
        border: none;
        outline: none;
      }

      button {
        border: none;
        outline: none;
      }

      input {
        font-size: 15px;
        flex-grow: 1;
        padding: 0;
        margin-right: 10px;
        border: none;
        outline: none;
      }

      button {
        border: none;
        outline: none;
      }

      .MuiSelect-icon {
        color: #333;
      }

    }

    .field-form {
      background-color: #F0F3F9;
      border: 1px solid #CDDBEF;
      border-radius: 5px;
      padding: 3px 10px;
    }

    .field-box {
      background-color: #F0F3F9;
      border: 1px solid #CDDBEF;
      border-radius: 5px;
      padding: 5px 10px;
      display: flex;
      align-items: center;

      .sync-state,
      .date {
        font-size: 15px;
        margin: 6px;
      }
    }

    .field-input {
      border: 1px solid #CDDBEF;
      border-radius: 5px !important;
      padding: 5px 10px;
      align-items: center;
      width: 100%;
    }
  }

  .specific-field {
    display: flex;

    .image-input-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;

      .logo-image {
        height: 60px;
        width: 60px;
        filter: grayscale(100%);
        border-radius: 20px;
      }
    }

    .field-content {
      flex: 1;
      display: flex;
      flex-direction: column;

      .MuiTextField-root {
        width: 100%;
      }
    }
  }

  .lock-check {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    flex-direction: row;
    margin-bottom: 0;

    label {
      margin-left: 10px;
      font-size: 14px;
    }
  }

  .form-title {
    flex-grow: 1;
    font-size: 14px;
    color: #333;
    margin: 0;
    font-weight: bold;
  }


  .SyncTopArea {
    margin-top: 15px;

    &__grid {
      display: grid;
      grid-template-columns: 80% 20%;
    }

    .enableAutomatic {
      color: #138EFF;
      font-size: 14px;
    }

    .lock-check-wrapper {
      border: 2px solid #138EFF;
      width: max-content;
      border-radius: 5px;
    }

    .lock-check {
      padding: 0px 10px;
      margin-top: 0;
    }

    .MuiSwitch-track {
      background-color: #218eff;
      opacity: 1;
    }

    .MuiTypography-root {
      font-size: 15px;
    }

    .last-refresh-time-svg {
      margin: -1px -10px 0 auto;
      background-color: #fff;
      border-radius: 5px;
      height: 47px;
      padding-top: 9px;
      width: 46px;
    }

    &__fistRow,
    &__secondRow {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    &__iconArea {
      display: flex;
      gap: 11px;
    }

    .inconSync {
      background-color: #fff;
      border-radius: 10px;
      width: 40px;
      height: 40px;
      text-align: center;

      img {
        padding-top: 11px;
      }
    }

    .SyncState {
      color: #77889F !important;
      font-size: 14px;
      font-weight: 500 !important;
    }

    p.sync-state {
      color: #12B076 !important;
      font-size: 14px;
      font-weight: 500 !important;
      margin-top: -3px;
    }

    .field {
      margin-bottom: 0;
    }

    &__btnArea {
      margin-top: 45px;
    }

    .inconSync.dateicon svg {
      margin-top: 9px;
    }

    .last p.field-label,
    .nextSync {
      color: #138EFF !important;
      font-size: 14px;
      font-weight: 500 !important;

      span {
        color: #77889F;
      }
    }

    p.date {
      color: #000000 !important;
      font-size: 14px;
      font-weight: 500 !important;
      margin-top: -4px;
      margin-bottom: 0;
    }

    &__lineArea>div {
      border-left: 1px solid #4ea8fe;
      padding-left: 20px;
      margin-left: 0px;
    }

    &__rightColumn {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .SyncNowBtn {
      color: #138EFF;
      background-color: #F5F7FB;
      border: 2px solid #138EFF;
      font-size: 14px;
      font-weight: 600;

      &:hover {
        color: #138EFF;
        background-color: #ffffff;
        border: 2px solid #138EFF;
      }

      img {
        margin: 2px 5px 3px 2px;
      }
    }


  }

  .MuiInputBase-input {
    height: 14px !important;
    font-size: 14px !important;
  }

  .form-container .lock {
    display: none;
  }

  .locked-form .unlocked {
    display: none;
  }

  .SyncBottomArea {

    margin-top: 70px;

    &__container {
      border-radius: 13px;
      background: #F5F7FB;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
      margin: 0 -25px;
    }

    &__topArea {
      display: flex;
      padding: 25px 25px 0 25px;
    }

    p.lock,
    p.unlocked {
      font-size: 14px;
      color: #77889F;

      img {
        margin-left: 5px;
        margin-top: -4px;
      }
    }

    .SyncBottomArea__firstRow,
    .SyncBottomArea__secondRow {
      gap: 22px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 0 25px;
    }

    .field-form,
    .MuiSelect-select {
      border: none;
    }

    .executionMode {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }

    .field-form.executionP span {
      font-size: 14px !important;
    }

    &__container p.field-label {
      margin-bottom: 5px !important;
    }

    .MuiSelect-select {
      padding: 13px 14px;
    }

    button.edit-button.cancelBTN {
      margin-right: 20px;
    }

    .startRow svg {
      color: #77889F !important;
    }

    .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
      font-size: 14px !important;
    }

    .form-container input {
      border: none;
    }




  }

}


.sync-management.newStore {

  background-color: transparent;
  padding: 20px;
  width: 100%;
  margin: 0 auto;
  font-family: "Source Sans Pro", sans-serif;
  max-width: 675px;
  width: 100%;

  .sync-header {
    display: flex;
    justify-content: flex-start;
    margin-bottom: -5px;
    align-content: center;
    align-items: flex-start;
    margin-top: 15px !important;
  }

  .form-container .column {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    flex-direction: inherit;
    justify-content: space-between;
  }

  .field {
    width: 48%;
  }

  .form-container input {
    width: 100%;
  }

  .form-container {
    margin-top: 30px !important;
  }

  .divider {
    margin: 15px 0;
  }

  .bfdStore .field-edit {
    max-width: 35px;
    border: none;
    background-color: transparent !important;
    padding-left: 0 !important;

    input {
      margin: auto !important;
      min-height: 20px !important;
    }
  }

  .field.bfdStore {
    flex-direction: row;
    gap: 10px;
    align-items: center;

  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
    border-top: 1px solid rgba(189, 208, 234, 0.50);
    padding-top: 25px;
    gap: 15px;
  }

  button.edit-button.cancelBtn {
    border-radius: 6px;
    border: 1px solid #FFF;
    background: #F9FAFD;

    &:hover {
      background: #ebebeb;
    }

  }

  .form-container .column {
    gap: 0;
  }

  .field .field-edit {
    padding: 0px 10px;
  }

  select#Store\ Plan {
    width: 100%;
    border: none;

    &:focus-visible {
      outline: none;
    }
  }

  .form-container input {
    padding: 10px;
    font-size: 16px !important;
    color: #77889F !important;
    min-height: 50px;
  }
}

.input-container {
  background-color: white;
  border: 1px solid #CDDBEF;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-right: 5px;

  input {
    font-family: $font;
    font-size: 90%;
  }
}

.dropdown {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  display: none;
  bottom: 100%;
  max-height: 300px;
  overflow-y: auto;
  border-radius: 0 0 10px 10px;
}

.dropdown.visible {
  display: block;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.dropdown-container {
  position: relative;
}

.input-container {
  position: relative;
  width: 100%;
}

.item-dropdown {
  font-family: $font;
  font-weight: 400;
  letter-spacing: 0.00938em;
  margin-left: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.item-dropdown:hover {
  background-color: rgb(238, 236, 236);
}

.dropdown-container {
  input {
    width: 100%;
    padding: 0;
    border: none;
    height: 50px;
    padding-left: 10px;
    box-sizing: border-box;
    border-radius: 3px 3px 3px 3px;
  }
}

.clear-CSV {
  border: none;
  background: transparent;
}


.csvDropdown {

  .input-container {
    padding: 0px !important;
    color: #77889F !important;
  }

  input.input-search,
  input.input-search::placeholder,
  .item-dropdown {
    color: #77889F !important;
    font-size: 16px;
  }

  .item-dropdown {
    padding: 4px 11px;
    margin-left: 0;
    margin-right: 0;
    border-bottom: 1px solid #f6f6f6;
  }

}


.synModal {
  max-width: 522px;
  border-radius: 10px;
  background: #FFF;
  margin: auto;
  padding: 1.2rem;
  font-family: $font;

  &__close {
    text-align: right;

    button {
      border: none;
      border-radius: 5px;
      background: rgba(189, 208, 234, 0.20);
      color: #77889F;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      border: none;
      padding: 11px 20px;
      transition: all .3s;

      svg {
        margin-left: 8px;
        margin-top: -4px;

        path {
          stroke: #7d8da2;
        }

      }

      &:hover {
        background: rgba(159, 174, 196, 0.2);
      }
    }

  }

  &__logo {
    text-align: center;
    margin: -48px 0 10px 0;
  }

  h1 {
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-transform: capitalize;
    margin-bottom: 20px;
  }

  &__greyArea {
    border-radius: 5px;
    background: rgba(189, 208, 234, 0.15);
    padding: 30px 20px 20px 20px;

    .synModal__item {
      margin-bottom: 10px;
      display: flex;
      gap: 20px;
      align-items: flex-start;
      font-family: $font;

      p {
        color: #0E2C4B;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        font-family: $font;
        line-height: 25.2px;
        text-transform: capitalize;
      }
    }



    .synModal__btnSync {
      margin-top: 25px;
      margin-bottom: 5px;
      text-align: center;

      button {
        background: #138EFF;
        width: 100%;
        color: #fff;
        border-radius: 5px;
        border: none;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        transition: all .3s;
        box-shadow: 0px 2px 4px 0px rgba(189, 208, 234, 0.30);
        letter-spacing: 0.18px;
        background-image: url(https://cdn.shopify.com/s/files/1/0622/8577/5024/files/sync_ed0b47fa-ad9d-4364-ab61-e9f54cf36d71.png?v=1701880343g);
        background-repeat: no-repeat;
        background-position: 4% 50%;
        min-height: 44px;

        &:hover {
          background: #1284ee;
          background-image: url(https://cdn.shopify.com/s/files/1/0622/8577/5024/files/sync_ed0b47fa-ad9d-4364-ab61-e9f54cf36d71.png?v=1701880343g);
          background-repeat: no-repeat;
          background-position: 4% 50%;
        }
      }
    }    

    input[type=checkbox] {
      background-color: initial;
      cursor: default;
      appearance: auto;
      box-sizing: border-box;
      margin: 14px 13px 15px 5px;
      padding: initial;
      border: initial;
    }

    input[type=checkbox]:after {
      width: 20px;
      height: 20px;
      top: -2px;
      left: -3px;
      border-radius: 2px;
      position: relative;
      background-color: #BDD0EA;
      content: "";
      display: inline-block;
      visibility: visible;
      border: 2px solid #BDD0EA;
    }

    input[type=checkbox]:checked:after {
      width: 20px;
      height: 20px;
      border-radius: 2px;
      top: -2px;
      left: -3px;
      position: relative;
      background-color: #138EFF;
      content: "";
      display: inline-block;
      visibility: visible;
      border: 2px solid #138EFF;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
      background-position: center center;
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 53px;
      height: 26px;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #BDD0EA;
      -webkit-transition: .4s;
      transition: .4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    input:checked+.slider {
      background-color: #138EFF;
    }

    input:focus+.slider {
      box-shadow: 0 0 1px #138EFF;
    }

    input:checked+.slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    .slider.round {
      border-radius: 16px;
    }

    .slider.round:before {
      border-radius: 50%;
    }


  }

  &__buttomArea {
    padding: 20px 20px 0 20px;

    h2 {
      color: #77889F;
      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      text-transform: capitalize;
      margin-top: 0px;
      margin-bottom: 12px;
    }

    .synModal__alert {
      margin-top: 35px;

      p {
        text-align: center;
        color: #77889F;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        text-transform: capitalize;

        span {
          color: #FF3D00;
        }

        img {
          margin-top: -5px;
          margin-right: 4px;
        }
      }

    }

    button.syncAll {
      border: none;
      color: #ffffff;
      width: 100%;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.18px;
      border-radius: 5px;
      background: #008856;
      box-shadow: 0px 6px 20px 0px rgba(63, 213, 158, 0.30);
      transition: all .3s;
      background-image: url(https://cdn.shopify.com/s/files/1/0622/8577/5024/files/sync_ed0b47fa-ad9d-4364-ab61-e9f54cf36d71.png?v=1701880343g);
      background-repeat: no-repeat;
      background-position: 4% 50%;
      min-height: 44px;

      &:hover {
        background: #006f47;
        background-image: url(https://cdn.shopify.com/s/files/1/0622/8577/5024/files/sync_ed0b47fa-ad9d-4364-ab61-e9f54cf36d71.png?v=1701880343g);
        background-repeat: no-repeat;
        background-position: 4% 50%;

      }

    }

  }

}

.synctype-container {
  display: flex;
  gap: 8px;
  margin-top: 26px;
  justify-content: space-evenly;
  margin-bottom: 22px;

  button {
    border: 2px solid #dee4ef;
    color: #778caa;
    background-color: #f5f7fb;
    padding: 5px 10px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    width: 82px;
    height: 35px;
    padding: 1px;

    &:hover {
      background-color: #f0f3f9;
    }

    &.regular.active {
      background-color: #1b77fd;
      border: 2px solid #1b77fd;
      color: white;
    }

    &.v2.active {
      background-color: #914cf3;
      border: 2px solid #914cf3;
      color: white;
    }

    &.v2-beta.active {
      background-color: #2cbc87;
      border: 2px solid #2cbc87;
      color: white;
    }
  }
}

.warning-box {
  background-color: #dee4ef;
  border: 1px solid #dee4ef;
  color: #1976d2;
  padding: 15px;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  margin-bottom: 20px;

  svg {
    margin-top: 1px;
    margin-right: 5px;
  } 

  .content {
    flex: 1;
    font-family: $font;
    font-size: small;

    p {
      margin-bottom: 10px;
      font-weight: 500;
      color: #1976d2;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}

.synctype-about {
  font-size: smaller;

  p.title {
    color: #333333;
    font-size: 20px;
    color: #333;
    font-weight: bold;
  }

  .about-content {
    p {
      margin-bottom: 10px;
      font-weight: 500;
      color: #333333;
    }
  }
}

.about-warning-box {
  background-color: #fff7d6;
  border: 1px solid #fff7d6;
  padding: 15px;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  margin-bottom: 20px;

  svg {
    margin-top: 1px;
    margin-right: 5px;
  } 

  .content {
    flex: 1;
    font-family: $font;
    font-size: small;

    p {
      margin-bottom: 10px;
      font-weight: 500;
      color: #856404;;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}

p.title-create-container {
  font-size: 17px;
    color: #333;  
    font-weight: 500;
    margin-bottom: -5px;
    margin-top: 30px !important;
}

.create-container-wrapper {

  .create-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: inherit;
    justify-content: space-between;
  }

  p.field-label {
    font-size: 14px !important;
    font-weight: 600 !important;
    font-family: 'Source Sans Pro', sans-serif !important;
    color: #138EFF;
    margin: 0;
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .create-container-item {
    width: 48%;
    .field-edit {
      .input-error {
        border: 1px solid #e46a6a;
      }
    } 
  } 
}

/*======================================== RESPONSIVE ========================================*/
/*--MOBILE--*/

@media (min-width: 1200px) {

  .sync-management.syncPanel {
    min-width: 680px;
  }

}

/*--MOBILE--*/

@media (max-width: 775px) {

  .sync-management {

    .SyncBottomArea__container {
      margin: 0;
    }

    .SyncBottomArea .SyncBottomArea__firstRow,
    .SyncBottomArea .SyncBottomArea__secondRow {
      gap: 0;
      grid-template-columns: 1fr;
    }

    .button-container {
      margin-top: 20px;
      margin-bottom: 30px;
    }

    .SyncTopArea__grid {
      grid-template-columns: 100%;
    }

    .SyncTopArea__rightColumn {
      align-items: flex-start;
      margin-top: 35px;
    }

    .SyncTopArea__btnArea {
      margin-top: 25px;
    }

    .SyncBottomArea {
      margin-top: 45px;
    }

    button.edit-button.cancelBTN {
      margin-bottom: 15px;
    }
  }


  .sync-management.newStore {
    padding: 10px 0;

    .field {
      width: 100%;
    }

    .form-container {
      flex-direction: inherit;
    }
  }

  .sync-management.syncPanel {

    button.edit-button.save {
      margin-left: 0;
    }

    .btnFooter {
      display: flex;
      align-content: center;
      align-items: flex-end;
      flex-direction: column;
    }

    button.edit-button.cancelBTN {
      margin-right: 0;
    }

  }

  .create-container-wrapper .create-container {    
    flex-direction: column; 
  }

  .create-container-wrapper .create-container-item {
    width: 100%;
  }

}

.modal-save {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-save-content {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  width: 300px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-save-content p {
  margin-bottom: 20px;
}

.modal-save-buttons {
  display: flex;
  justify-content: space-around;
}