$font: 'Source Sans Pro', sans-serif;

* {
  -webkit-font-smoothing: antialiased;
}

.store-list-filter {
  font-family: $font;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filters-settings {
  display: flex;
  align-items: center;

  p {
    margin-bottom: -5%;
    font-family: $font;
    color: black;
    font-weight: 600;
    margin-left: 5%;
  }

  button {
    padding: 5px 15px;
    background-color: #DEE9FA;
    color: #77889F;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 90%;
    width: 100%;
    margin-left: 25%;

    &:hover {
      background-color: #d0dbec;
    }
  }
}

.filter-options {
  display: flex;
  align-items: center;

  .order-by-name {
    margin-right: 16px;
  }

  .order-by-sync {
    margin-right: 16px;
  }

  .filter-stores-plan {
    margin-right: 16px;
  }

  .filter-stores {
    position: relative;
    display: flex;
    align-items: center;
  }

  .filter-dropdown {

    padding: 5px;
    background-color: #F0F3F9;
    color: #333;
    cursor: pointer;
    font-size: 16px;
    border: none;

    &:focus {
      outline: none;
    }

    & option {
      background-color: black;
      color: white;
      border: none;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      font-size: 85%;
    }
  }
}

.refresh-container {
  position: relative;
  display: inline-block;
  font-family: $font;
  box-shadow: 0px 2px 4px 0px rgba(179, 197, 223, 0.3);
  background-color: #f6f8fc;
  border-radius: 5px;
  max-width: 136px;
  height: 48px;
  display: flex;
  gap: 10px;
  align-items: center;

  &:hover {
    background-color: #f9f9f9;
  }

  button {
    padding: 0;
    color: #138EFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    font-size: 87%;
    min-height: 36px;
    background-color: #f6f8fc;
    width: 100%;
  }

  .firstSvg {
    margin-left: -9px;
    margin-top: -2px;
  }

  button.refresh-button {
    margin-left: 13px;
    padding-right: 0;

    svg {
      margin-top: -3px;
    }
  }

  .refresh-dropdown {
    position: relative;
    display: inline-block;

    .refresh-button {
      background: none;
      border: none;
      cursor: pointer;
      font-size: 85%;
    }

    .dropdown-content:before {
      content: url('https://cdn.shopify.com/s/files/1/0622/8577/5024/files/Vector_38.png?v=1698165208');
      display: block;
      width: 18px;
      height: 18px;
      position: absolute;
      left: 42%;
      top: -15px;
    }

    .dropdown-content {
      font-family: "Source Sans Pro", sans-serif;
      color: white;
      font-weight: 600;
      border-radius: 5px;
      display: none;
      position: absolute;
      top: 111%;
      font-size: 15px;
      right: -392%;
      background-color: #000000;
      min-width: 200px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 999999999999;
      padding: 10px 10px;


      label,
      hr {
        display: flex;
        align-items: center;
        padding: 5px;

        input[type="checkbox"],
        input[type="radio"] {
          margin-right: 5px;
        }
      }

      label {
        cursor: pointer;
      }

      label.repeatEvery {
        color: #77889F;
        cursor: none;
      }

      hr {
        border: 0;
        border-top: 1px solid #ccc;
        width: 100%;
        margin: 0;
      }
    }

    &:hover .dropdown-content {
      display: block;
    }
  }
}

.slider-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
}

.slider-menu {
  position: absolute;
  top: 0;
  right: 0;
  width: 25%;
  height: 100%;
  background: #f0f3f9;
  z-index: 997;
  font: $font;
  display: flex;
  align-items: flex-start;

  &.lateral {
    background: #000000;
    padding: 2%;
  }

  .close-button {
    border: none;
    background-color: #000;
  }

  h1 {
    color: rgba(255, 255, 255, 0.87);
    font-family: Source Sans Pro;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: capitalize;
    margin-top: 50px;
  }

  .filters-div {

    p {
      color: rgba(255, 255, 255, 0.87);
      font-family: Source Sans Pro;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 10px;
    }

    label {
      display: block;
      margin-bottom: 5px;
      color: #F0F3F9;
      font-family: Source Sans Pro;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      text-transform: capitalize;

      input[type="checkbox"] {
        margin-right: 7px !important;
      }

    }
  }

  .plan-filter-div {


    p {
      color: rgba(255, 255, 255, 0.87);
      font-family: Source Sans Pro;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 10px;
    }

    select {
      margin-bottom: 5%;
    }
  }

  .menu-button-div {
    display: flex;
  }

  .left-button {
    display: flex;
    width: 100%;
    gap: 12px;
  }

  .right-button {
    display: flex;
    position: relative;
  }

  .dropdown-wrapper {
    position: relative;
  }

  .plan-filter-div {
    border-top: 1px solid rgba(189, 208, 234, 0.30);
    margin-top: 25px;
    padding-top: 20px;
  }

  .filters-div {
    margin-top: 2px;
    padding-top: 22px;
    padding-bottom: 20px;
    border-top: 1px solid rgba(189, 208, 234, 0.30);
    border-bottom: 1px solid rgba(189, 208, 234, 0.30);
  }

  select#filter-plan {
    width: 100%;
    background-color: #000;
    color: #fff;
    padding: 7px 8px;
    border-radius: 4px;
    font-size: 16px;
    font-family: "Source Sans Pro", sans-serif;
    appearance: none;
    background-image: url("https://cdn.shopify.com/s/files/1/0622/8577/5024/files/Vector_9.svg?v=1698779076");
    background-repeat: no-repeat;
    background-position: right 10px center;
    outline: none;
    cursor: pointer;
  }

  select#filter-status {
    width: 100%;
    background-color: #000;
    color: #fff;
    padding: 7px 8px;
    border-radius: 4px;
    font-size: 16px;
    font-family: "Source Sans Pro", sans-serif;
    appearance: none;
    background-image: url("https://cdn.shopify.com/s/files/1/0622/8577/5024/files/Vector_9.svg?v=1698779076");
    background-repeat: no-repeat;
    background-position: right 10px center;
    outline: none;
    cursor: pointer;
  }

  .dropdown-content {
    font-family: "Source Sans Pro", sans-serif;
    color: white;
    font-weight: 600;
    border-radius: 5px;
    display: none;
    position: absolute;
    top: calc(100% + -1px);
    right: -84%;
    font-size: 15px;
    background-color: #000000;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    padding: 10px 10px;

    label {
      display: flex;
      align-items: center;
      padding: 5px;

      input[type="checkbox"],
      input[type="radio"] {
        margin-right: 5px;
      }
    }

    label {
      cursor: pointer;
    }

    hr {
      border: 0;
      border-top: 1px solid #ccc;
      width: 100%;
      margin: 0;
    }

    &:before {
      content: url('https://cdn.shopify.com/s/files/1/0622/8577/5024/files/Vector_38.png?v=1698165208');
      display: block;
      width: 18px;
      height: 18px;
      position: absolute;
      left: 42%;
      top: -15px;
    }
  }

  .dropdown-wrapper:hover .dropdown-content {
    display: block;
  }

  .filter-button {
    padding: 9px 11px;
    border: none;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
    font-size: 90%;
    font-family: $font;
    color: #000000;
    display: flex;
    min-width: 100px;
    align-items: center;
    gap: 9px;

    &:hover {
      background-color: #f9fbff;
    }
  }



  .apply-button-div {
    text-align: right;
    margin-top: 7%;

    .ApplyBtn {
      margin-right: 5px;
      font-size: 90%;
      font-family: $font;
      background-color: #000 !important;
      color: #fff;
      padding: 5px 20px !important;
      border: 1px solid #fff !important;
      border-radius: 3px !important;
      transition: all .3s;

      &:hover {
        background-color: #232323 !important;
      }
    }

    .ClearBtn {
      margin-right: 10px;
      border: none;
      font-size: 90%;
      font-family: $font;
      border-radius: 5px;
      background-color: #42464B !important;
      color: #fff;
      padding: 5px 20px !important;
      border: 1px solid #42464B !important;
      border-radius: 3px !important;
      transition: all .3s;

      &:hover {
        background-color: #232323 !important;

      }
    }


  }
}

.filterBar.productFilters {

  .filters-div {
    margin-top: 25px;
    padding-top: 20px;
  }

  label {
    min-width: 90px;
  }

}

.div-tabs.reportTable {
  overflow: auto;
}



.slider-menu-trigger {
  position: relative;
  z-index: 1001;
}

.filterBar {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: stretch;
  z-index: 999;
  position: relative;
  gap: 10px;

  button {
    background: transparent;
    border: none;
    padding-bottom: 3px;

    &.activeMode {
      background-color: #fff;
      border-radius: 4px;
    }
  }

  .viewMode {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
  }

  .cBtn {
    justify-content: center;
    background-color: #F4F7FD;
    color: #138EFF;
    box-shadow: 0px 2px 4px 0px rgba(189, 208, 234, 0.30);
    min-width: 70px;
    margin-left: 12px;
  }

}

.storelist-type-container {
  display: flex;
  gap: 10px;
  margin-bottom: -55px;
  margin-left: 176px;
  z-index: 990;
  position: relative;

  .type-store-button {
    background-color: white;
    padding: 10px;
    display: flex;
    gap: 5px;
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    align-items: center;
    width: 161px;
    justify-content: space-evenly;

    .store-type-icon img {
      width: 30px;
      height: 30px;
      border-radius: 5px;
    }
  }

  label.store-type-label {
    font-weight: 500;
  }

  input.store-type-checkbox {
    height: 26px;
    width: 16px;
    cursor: pointer;
  }
}




/*======================================== RESPONSIVE ========================================*/


/*--MOBILE--*/

@media (max-width: 775px) {

  .reportTable {

    .search-input-products {
      justify-content: flex-start;
    }

    input#product-search {
      max-width: 257px;

    }

  }

}