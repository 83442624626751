.admin-portal-header {
    color: #333;
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    .header-content {
      display: flex;
      align-items: center;
    }
  
    .btn-logout {
      background-color: transparent;
      background: none;
      border: none;
      padding: 15px;
      cursor: pointer;
    }
    .header-date-container svg {
      margin-right: 7px;
  }
  
    .header-date-container {
      display: flex;
      align-items: center;
  
      label {
        display: flex;
        align-items: center;
        margin-right: 15px;
        color: white;
        font-weight: bold;
        font-size: 85%;
        gap: 12px;
        border-right: 1px solid #176ab6;
        padding-right: 14px;
      }
  
      input[type="checkbox"] {
        appearance: none;
        width: 30px;
        height: 15px;
        position: relative;
        cursor: pointer;
  
        &:before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          background: #ccc;
          border-radius: 15px;
          position: absolute;
          transition: background-color 0.3s;
        }
  
        &:checked:before {
          background: #1B78CE;
        }
  
        &:after {
          content: "";
          display: block;
          width: 50%;
          height: 100%;
          background: #fff;
          border-radius: 50%;
          position: absolute;
          left: 0;
          transition: left 0.3s;
        }
  
        &:checked:after {
          left: 50%;
        }
      }
    }
  }
  