.sidebar {

  &-container {
    height: 100%;
    transition: width 0.3s ease;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 1px 0 3px rgba(0, 0, 0, 0.05);
    font-size: 14px;

    hr {
        color: #d3d5d9 !important;
    }

    .shopify-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 25px 0px 5px 14px;
    
        .shopify-title {
          display: flex;
          gap: 5px;
          align-items: center;
    
          p {
            font-weight: 600;
            color: #000;
            margin-bottom: 0;
            font-size: .7rem;
            transition: all .3s;
          }
    
        }
        
        .shopify-button {
            svg {
                transform: rotate(90deg);
                path {
                    stroke: #138EFF;
                }
            }
        }
    }

    p.sync-title {
       svg {
        margin-left: 10px;
        margin-right: 5px;
         path {
            fill: #000;
         }
       }
    }
  }

  &-open {
    width: 250px;

    .store-type-list {
        span {
            margin-left: 10px;
            color: #7E7E7E;
        }
    }

    p.sync-title {
        font-weight: 600;
        color: #000;
        margin-bottom: 0;
        font-size: .7rem;
        transition: all 0.3s ease;
        text-transform: uppercase;
        margin-top: 26px;
    }
  }

  &-closed {
    width: 60px;

    .shopify-title {

        margin-left: 6px;

        p {
            font-size: 0px !important;
        }

        svg {
            height: 0px;
            width: 0px;
        }
    }
    
    .shopify-wrapper {
        padding-left: 0;
    }
    .shopify-button {

        .sidebar-toggle{
            margin-right: 0;
            margin-left: -48px;
        }
        svg {
            transform: rotate(270deg) !important;
        }
    }

    .store-type-list {
        span {
            font-size: 0px !important;
        }
    }

    p.sync-title {
        font-size: 0px !important;
        margin-left: 7px;
    }

    .sync-widget {
        .content {
            .none {
                font-size: 0px !important;
            }

            .warning-message {
                .warning-number-container {
                    .warning-number {
                        margin-left: 5px;
                    }
                    p.warning-number.additional {
                        font-size: 0px !important;
                    }
                }
                p.warning-text {
                    font-size: 0px !important;
                    
                    svg {
                        margin-left: 3px;
                    }
                }
            }
        }
    }

    .sync-widget-card {
        .sync-widget-details {
            
            .store-widget-name {
                font-size: 0px !important;
            }
      
            .websystem-text {
                font-size: 0px !important;
            }
        }
    }

    .sync-widget-loading {
        margin-left: 0;
    }

  }

  &-toggle {
    cursor: pointer;
    background-color: #fff;
    border-radius: 50%;
    margin-right: -16px;
    border: none;
    height: 36px;
    width: 36px;
  }

  &-content {
    padding: 10px 5px;
    overflow-y: auto;
    flex-grow: 1;

    hr {
        margin-left: -5px;
        margin-right: -5px;
    }
  }

  &-title {
    font-weight: bold;
    margin-bottom: 15px;
    font-size: 16px;
  }
}

.button-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  li {
    margin-bottom: 8px;

    :hover {
        background-color: #f9fbfe;
        border-radius: 5px;
        transition: all 0.3s ease;
    }

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 11px;
      border-radius: 5px;
      transition: all 0.3s ease;

      &.selected {
        background-color: #F0F3F9;

        button {
            background-color: #F0F3F9;
        }

        span {
            color: #000;
            background-color: #f0f3f9;
        }
      }
    }

    span {
      font-size: 13px;
    }
  }
}

.store-type-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  transition: all 0.3s ease;

  span {
    transition: all .3s;
  }

  img {
    border-radius: 5px;
    width: 28px;
    height: 28px;
  }
}

.sync-widget {
    .content {
        .list {
            display: flex;
            flex-direction: column;
            gap: 12px;
            align-items: center;
            margin-top: 15px;

        }

        .warning-message {
            .warning-number-container {
                display: flex;
                align-items: center;
                gap: 5px;
                margin-left: 6px;

                .warning-number {
                    color: black;
                    font-weight: 600;
                    font-size: 0.7rem;
                    text-transform: uppercase;
                    margin-top: 13px;
                }

                p.warning-number.additional {
                    transition: all .3s;
                }
            }
            p.warning-text {
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all .3s;
                padding: 10px;
                background-color: #FFEAEA;
                border-radius: 8px;
                font-size: 13px;
                color: #C74646;
                font-weight: 500;

                svg {
                    margin-right: 5px;
                    width: 60px;
                    height: 60px;
                }
            }
        }

        .none {
            margin: 16px 0 0 10px;
            font-size: 13px;
            transition: all .3s;
        }
    }
}

.sync-widget-loading {
    display: flex;
    justify-content: center;
    margin-top: 28px;
    margin-left: -10px;

    .spinner {
      svg {
        width: 20px !important;
      }
    }
}  

.sync-widget-card {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px;
  width: 100%;
  
  img {
    max-width: 40px;
    min-height: 40px;
    height: 100%;
    border-radius: 10px;
    border: 1px solid #BDD0EA;
    object-fit: contain;
    background-color: white;
    padding-left: 4px;
    padding-right: 3px;
  }

  .sync-widget-details {
      display: flex;
      flex-direction: column;

      .store-widget-name {
          font-size: 13px;
          font-weight: 500;
          margin: 0;
          color: black;
          word-break: break-word;
          transition: all .3s;
      }

      .websystem-text {
          font-size: 11px;
          margin-bottom: 0;
          transition: all .3s;
      }
  }
}