.customization-tool-container { 
    .categories .topBar {
        background-color: #7dc4e2;
        height          : 17px;
    }
    
    .categories .titleHeader {
        padding         : 50px 50px 10px 50px;
        background-color: #d8edf6;
    }
    
    .categories h1.comming-soon {
        color     : #7dc4e2;
        text-align: center;
        margin    : 3rem;
    }
    
    .bigInput {
        margin-top: -37px;
    }
    
    .bigInput input {
        height        : 80px !important;
        padding-bottom: 41px;
    }
    
    .greeNumber p {
        font-size: 30px;
        color    : #2dbb7a;
    }
    
    .headerCategories {
        display      : flex;
        gap          : 15px;
        margin-bottom: 10px;
    }
}

.customization-tool-container { 
    .tagline .topBar {
        background-color: #e99c9c;
        height          : 17px;
    }
    
    .tagline .titleHeader {
        padding         : 50px 50px 10px 50px;
        background-color: #f0cfcf;
    }
}