@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,800&display=swap');

.customization-tool-container {

    min-width: 890px;
    margin: auto;

    p,
    h1,
    h2,
    h3,
    li {
        font-family: 'Plus Jakarta Sans';
    }

    .container {
        background-color: #eaeff6;
        border-radius: 0 0 15px 15px;
        max-width: 870px;
        margin-bottom: 50px;
    }

    /* Basic styling */

    [type=checkbox] {
        width: 2rem;
        height: 2rem;
        color: #2dbb7a;
        vertical-align: middle;
        -webkit-appearance: none;
        background: none;
        border: 0;
        outline: 0;
        flex-grow: 0;
        border-radius: 4px;
        background-color: #FFFFFF;
        transition: background 300ms;
        cursor: pointer;
        margin-top: 24px;
    }


    /* Pseudo element for check styling */

    [type=checkbox]::before {
        content: "";
        color: transparent;
        display: block;
        width: inherit;
        height: inherit;
        border-radius: inherit;
        border: 0;
        background-color: transparent;
        background-size: contain;
        box-shadow: inset 0 0 0 1px #CCD3D8;
    }


    /* Checked */

    [type=checkbox]:checked {
        background-color: currentcolor;
    }

    [type=checkbox]:checked::before {
        box-shadow: none;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
    }


    /* Disabled */

    [type=checkbox]:disabled {
        background-color: #CCD3D8;
        opacity: 0.84;
        cursor: not-allowed;
    }


    /* IE */

    [type=checkbox]::-ms-check {
        content: "";
        color: transparent;
        display: block;
        width: inherit;
        height: inherit;
        border-radius: inherit;
        border: 0;
        background-color: transparent;
        background-size: contain;
        box-shadow: inset 0 0 0 1px #CCD3D8;
    }

    [type=checkbox]:checked::-ms-check {
        box-shadow: none;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
    }

    /* UPLOAD STYLES*/
    .avatar-upload {
        position: relative;
        /*max-width: 205px;
    margin     : 50px auto;*/
    }

    .avatar-upload .avatar-edit {
        position: absolute;
        z-index: 1;
        bottom: 0;
        right: 0;
    }

    .avatar-upload .avatar-edit input {
        display: none;
    }

    .avatar-upload .avatar-edit input+label {
        display: inline-block;
        width: 49px;
        height: 51px;
        margin-bottom: -33px;
        border-radius: 4px;
        background: #FFFFFF;
        border: 1px solid transparent;
        /*box-shadow : 0px 2px 4px 0px rgba(0,0,0,0.12);*/
        cursor: pointer;
        font-weight: normal;
        transition: all .2s ease-in-out;
        background: url('../../assets/customization/upload.png') no-repeat 15px 18px !important;
    }

    .avatar-upload .avatar-edit input+label:hover {
        background: #f1f1f1;
    }

    .avatar-upload .avatar-edit input+label:after {
        content: "";
        font-family: 'FontAwesome';
        color: #757575;
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
        text-align: center;
        margin: auto;
    }

    .avatar-upload .avatar-preview {
        width: 100%;
        height: 130px;
        position: relative;
        /*border-radius: 100%;
    border         : 6px solid #F8F8F8;
    box-shadow     : 0px 2px 4px 0px rgba(0,0,0,0.1);*/
    }

    .avatar-upload .avatar-preview>div {
        width: 100%;
        height: 100%;
        /*border-radius    : 100%;*/
        /*background-size  : cover;*/
        background-repeat: no-repeat;
        background-position: center;
    }

    .container.up {
        max-width: 329px;
        margin-bottom: 0;
        border-radius: 4px;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

    h4 {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.05em;
        color: #88ACC7;
        padding-bottom: 12px;
        padding-left: 15px;
    }

    .smallup div#imagePreview {
        background-size: 70%;
    }

    .smallup h4 {
        font-size: 11px;
        padding-bottom: 38px;
        padding-left: 15px;
        margin-top: -26px;

    }

    a:hover {
        text-decoration: none;
    }

    p,
    li,
    label {
        color: #416267;
    }

    label {
        font-size: 14px;
        font-weight: 300;
    }

    p.font-13 {
        font-size: 13px;
        line-height: 21px;
    }

    .wrapperContent {
        p {
            font-size: 12px;
            line-height: 18px;
            margin-bottom: 6px;
        }

        &.true {
            position: relative;
        }

        &.coming-soon {
            position: relative;
        }

        .alert.alert-danger {
            margin-top: 28px;
        }

        .disabled-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            padding: 30%;
            z-index: 1000;
            border-radius: 0 0 15px 15px;
            backdrop-filter: blur(1.2px);
            -webkit-backdrop-filter: blur(1.2px);

            .coming-soon-message {
                background-color: #655a7c;
                border-radius: 30px;
                padding: 30px;
                height: 145px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

                p,
                h1 {
                    color: white;
                }
            }
        }

        .custom-control.custom-switch {
            display: flex;
            align-content: center;
            flex-direction: row-reverse;
            align-items: center;
            gap: 10px;
            margin-top: -18px;

            label {
                margin-bottom: -24px;
            }
        }
    }



    h1 {
        font-weight: 600;
        font-size: 25px;
        line-height: 32px;
        letter-spacing: 0.02em;
        color: #416267;
    }

    h2 {
        font-weight: 600;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.02em;
        color: #07BB7A;
    }

    .titleHeader {
        min-height: 60px;
    }

    .wrapperContent {
        padding: 10px 30px 20px 30px;
        min-height: 80vh;

        .notification-container {
            position: relative;
        }
    }

    .custom-control-input:checked~.custom-control-label::before {
        color: #fff;
        border-color: #07BB7A;
        background-color: #07BB7A;
    }

    input.input-round.form-control {
        width: 328px;
        height: 45px;
        background: #FFFFFF;
        opacity: 0.7;
        border-radius: 20px;
        font-size: 13px;
        padding-top: 5px;
    }

    input.input-round.form-control::placeholder {
        font-size: 13px;
        color: #416267;
    }

    input.input-round-number.form-control {
        width: 328px;
        height: 45px;
        background: #FFFFFF;
        opacity: 0.7;
        border-radius: 20px;
        font-size: 13px;
        padding-top: 5px;
    }

    input.input-round-number.form-control::placeholder {
        font-size: 13px;
        color: #416267;
    }

    .pl-7 {
        padding-left: 7rem;
        padding-right: 0;
    }

    .pl-35 {
        padding-left: 3.5rem;
        padding-right: 0;
        margin-left: 19px;
    }

    hr {
        border-top: 2px solid #d1deea;
        margin-top: 40px;
    }

    .navigationFooter {
        li {
            position: absolute;
            left: 0;
            color: #EA0062;
            font-size: 14px;

        }

        position : relative;
        display : flex;
        justify-content: flex-end;
        gap : 15px;
        margin-bottom : 20px;
    }

    span.saveBtn {
        background: #416267;
        border-radius: 200px;
        color: #fff;
        padding: 9px 55px;
        font-size: 14px;
        transition: all .5s;
        cursor: pointer;

        &.disabled {
            cursor: not-allowed;
        }
    }

    span.saveBtn:hover {
        background: #151e20;

    }

    a.backBtn {
        background: #eaeff6;
        border-radius: 200px;
        color: #416267;
        padding: 9px 55px;
        font-size: 14px;
        transition: all .5s;
        border: 2px solid #88acc7;
        cursor: pointer;
    }

    a.backBtn:hover {
        background-color: #ffffff;
        border: 2px solid #416267;
        color: #416267;
    }

    input.link {
        background: url('../../assets/customization/link.png') no-repeat 16px 16px !important;
        background-color: white !important;
        padding-left: 39px;
    }

    .bl-1 {
        border-left: 2px solid #d1deea;
    }

    .notification {
        position: absolute;
        top: -36px;
        right: 10px;
        z-index: 1000;
        padding: 12px 20px;
        border-radius: 6px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.10);
        font-size: 14px;
        font-weight: 500;
        color: #fff;

        &.success {
            background-color: #4caf50;
        }

        &.error {
            background-color: #e34c41;
        }
    }

    .tagline-input-wrapper.mt-4.url {
        display: flex;
        align-items: center;
        gap: 35px;
    }
}