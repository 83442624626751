.customization-tool-container {

    .container.welcome {
        
        .col-sm.col-right.text-right {
            text-align: right;
        }
    }

    .welcome .col-left {
        padding-right: 40px;
    }

    .welcome .topBar {
        background-color: #07BB7A;
        height: 17px;
    }

    .numberOption__wrapper {
        background-color: #fff;
        border-radius: 3px;
        display: flex;
        max-width: 275px;
        margin-top: 5px;
        position: relative;
        overflow: hidden;

        .ribbon-customization {
            span {
                font-size: 8px;
                color: #fff;
                text-transform: uppercase;
                text-align: center;
                font-weight: bold;
                line-height: 17px;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                width: 103px;
                display: block;
                background: #655a7c;
                position: absolute;
                left: 222px;
                bottom: 5px;
                padding-right: 34px;
                margin-left: 1px;
            }
        }
    }

    .numberOption__wrapper:hover {
        filter: brightness(96%);
    }

    .numberOption__number {
        border-radius: 3px;
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        color: #fff;
        width: 40px;
        height: 40px;
        text-align: center;
        padding-top: 6px;
    }

    .one .numberOption__number {
        background-color: #84F8CE;
    }

    .two .numberOption__number {
        background-color: #7DC4E2;
    }

    .three .numberOption__number {
        background-color: #F6F0B7;
    }

    .four .numberOption__number {
        background-color: #FFD4AD;
    }

    .five .numberOption__number {
        background-color: #FFB4CF;
    }

    .six .numberOption__number {
        background-color: #C4B6FF;
    }

    .seven .numberOption__number {
        background-color: #e99c9c;
    }

    .numberOption__description {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.05em;
        color: #233638;
        padding: 12px 0 7px 12px;
    }

    .numberOption__description p {
        margin-bottom: 0;
    }
}