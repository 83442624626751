$font: 'Source Sans Pro', sans-serif;


.store-section-list {
    flex-direction: column;
    display: flex;
    background-color: white;
    border-radius: 10px;
    margin-top: 1%;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.03);
    padding: 20px 30px;
    overflow: auto;

    .no-results {
        text-align: center;
        font-weight: 500;
        color: #208eff;
    }

    .list-view-item {
        width: 100%;
    }
}



.list-item {
    font-family: $font;
    font-size: 80%;   
  
    

    .item-line {
        display: flex;

        &.header{
            font-weight: bold;
        }

        .item-column {
            flex: auto;
            padding: 2px;
            text-align: left;
            display: flex;        
            align-items: center;
            color: #4F627C;

            &:not(:last-child) {
                border-right: 0.5px solid #F0F3F9;
                margin-right: 15px;   
                padding: 0;
                min-height: 50px;         
            }

            &.alert {
                color: #C74646;                 
            }

            

            .square {
                width: 17px;
                height: 17px;               
                border-radius: 100px;
                border: 2px solid white;
                position: relative;

                &.off {
                    background-color: #BDD0EA;
                }
                &.dev {
                    background-color: #FEDB21;
                }
                &.live {
                    background-color: #3FD59E;
                }
                &:after {
                    content: attr(data-tooltip);
                    display: none;
                    position: absolute;
                    bottom: 20px;
                    left: 50%;
                    transform: translateX(-50%);
                    padding: 5px;
                    background-color: #333;
                    color: white;
                    font-weight: 400;
                    border-radius: 5px;
                    width: max-content;
                }
                &:hover:after {
                    display: block;
                }
            }
            
            .btn-list-sync {
                max-width: 160px;
                background-color: #fff;
                border: 1px solid #045c7c;
                color: #045c7c;
                font-weight: 600;
                font-size: 12px;
                box-shadow: 0px 2px 4px rgba(189, 208, 234, 0.3);
                border-radius: 5px;
                padding: 5px 11px 7px 11px;
                margin-top: -2px;
                img {
                    margin-right: 4px;
                }

                &:hover {
                    background-color: rgb(122, 166, 183);
                    color: white;
                }
            }
        }
    }
}



.list-view-item{
    flex-grow: 1;
}

.list-view-item:nth-of-type(odd){ 
    background: rgb(255, 255, 255);
}

.list-view-item:nth-of-type(even){    
    background: #F8FAFF;
}
.list-item.headerTitle {
   border-bottom: 1px solid #F0F3F9;
   margin: 0;    
}

.frequency-text {
    font-family: $font;
    font-size: 80%;
    flex: auto;
    text-align: left;
    align-items: center;
    
    p {
        color: #4F627C;
        font-weight: 400;
        margin: 0%;
        padding: 2px;
    }
}

.item-column.header {
    font-size: 16px;
    font-weight: 600;
    color: #000 !important;
    padding: 10px 0px !important;
}
.item-column.header.state {
    width: 70px!important;
    max-width: 70px!important;
    text-align: center !important;
    padding-left: 15px !important;
    width: 70px;
}

.item-column.stateRow {
    max-width: 70px;
    width: 70px;
    .square {
        margin: auto;
    }
}
.item-column.header.StoreId {
    max-width: 80px;
    width: 80px;
}
.item-column.storeIdRow {
    max-width: 80px;
    width: 80px;
    color: #000!important;    
    font-size: 16px;
}

.item-column.SynchronizingRow {
   button {
    margin: 2px;
   } 
}

.item-column.header.friendlyName, .item-column.friendlyNameRow {
    width: 180px !important;
    max-width: 180px!important;
}

.item-column.header.csv, .item-column.csvRow {
    width: 260px !important;
    max-width: 260px!important;
}

.item-column.header.storePlan, .item-column.planRow {
    width: 155px !important;
    max-width: 155px!important;
}
.item-column.header.frequency, .item-column.frequencyRow {
    width: 200px !important;
    max-width: 200px!important;
}

.item-column.header.scheduled, .item-column.scheduledRow {
    width: 200px !important;
    max-width: 200px!important;
}
.item-column.alert {
    margin: 0;
}

.list-item.headerTitle, .list-view-item {
    min-width: 1490px;
}
.compare-div {
    display: none;
}
.item-column.scheduledRow p, .frequency-text p {
    color: #4F627C;
    font-weight: 400;
    margin: 0%;
    padding: 2px;
    font-size: 11px;
    line-height: 22px;
}


/*======================================== RESPONSIVE ========================================*/



/*--Tablet--*/

@media (max-width: 1290px) {

    .store-section-list {
        overflow: auto;
        max-width: 92vw;     
        padding: 20px 15px; 
        
        .list-item {
            width: max-content;
            padding-right: 30px;
        }
        .list-view-item:nth-of-type(even) {            
            width: min-content;
        }
    }

    .store-report-container .differences-div {
        margin: 20px auto;
        padding-left: 0;
        float: left;
    }
    
}