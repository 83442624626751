$font: 'Source Sans Pro', sans-serif;
$background-color: #f0f3f9;


.store-report-container {
  font-family: $font;

  .store-info {

    .storeLogo {
      margin-top: -55px;
    }

    .store-details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 20px;

      h1 {
        font-size: x-large;
        font-weight: bold;
        margin-bottom: auto;
      }

      .store-plan {
        font-size: small;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }

      .report-title {
        font-size: 24px;
        margin-bottom: 10px;
      }

      .website {
        display: flex;
        align-items: center;

        img {
          width: 20px;
          margin-right: 5px;
          margin-top: -15px;
        }

        .title {
          margin-right: 10px;
          color: #138EFF;
          font-size: small;
        }

        a {
          text-decoration: none;
          color: #138EFF;

          &:hover {
            color: rgb(42, 42, 255);
          }
        }
      }
    }
  }

  .last-refresh-time {
    display: flex;
    flex-direction: column;
    margin-left: auto;

    .last-refresh-time-div {
      background-color: $background-color;
      font-size: small;

    }

    .last-refresh-time-svg {
      background-color: white;
      border-radius: 10px;
      display: flex;
      align-items: center;
      padding: 5px;
      width: 30px;
      height: 30px;
      box-sizing: border-box;
    }

  }
}

div.products {
  width: 100%;
  overflow: hidden;
  background-color: white;
  border-radius: 10px;
  font-family: $font;
}

div.compare {
  float: left;
  margin: 7px;
}

div.differences {
  overflow: hidden;
  margin-top: 15px;
}

.div-content {
  background-color: white;
}

.button {
  padding: 10px 20px;
  background-color: $background-color;
  border: 1px solid white;
  border-radius: 5px;
  font-family: $font;
  font-weight: bold;
  cursor: pointer;
  padding-left: 30px;
  padding-right: 30px;
}

.sync-btn {
  background-color: $background-color;
  cursor: pointer;
  border-radius: 5px;
  border: 0.5px solid white;

}

.button.active {
  background-color: white;
}

.div-content .tab-content.disabled {
  display: none;
}

.tab-content.active {
  display: block;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}



.product-sync {
  display: flex;
  align-items: center;
}



.spinner-div, .spinnerReport {
  align-items: center;
  justify-content: center;
  width: 100%;
}
.spinnerReport {
  width: 100%;
  height: 100%;
  padding: 100px;
  text-align: center;
}




.open-external {
  cursor: pointer;
}


.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.filter-show-only {
  padding: 10px !important;
  width: 250px !important;
}

.proceed-msg {
  padding: 20px;
  padding: 70px 20px;
  text-align: center;

  p {
    margin: 10px 0;
    font-family: $font;
    color: #000;
    font-weight: 500;
  }

  input[type="button"] {
    background-color: #138EFF;
    font-family: $font;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
      background-color: rgb(1, 78, 160);
    }
  }
}

.back-button {
  background-color: transparent;
  background: none;
  border: none;
  padding: 15px;
  cursor: pointer;
  align-items: flex-start !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  color: #16a4f7 !important;
  font-size: 16px !important;
  background: url(https://cdn.shopify.com/s/files/1/0622/8577/5024/files/Vector_5arrow_back.png?v=1696947840) no-repeat;
  padding-left: 35px !important;
  background-position: 18px center;
  text-transform: initial !important;
}

.showing-range {
  font-size: 14px;
  color: #888;
  margin-top: 10px;
}

.pagination {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .page-item {
    display: inline-block;
    margin: 0 5px;
    font-size: 16px;

    .page-link {
      text-decoration: none;
      color: #333;
      padding: 5px 10px;
      border: 1px solid #ccc;
      border-radius: 3px;
      transition: background-color 0.3s;

      &:hover {
        background-color: #f0f0f0;

        svg {
          fill: #ffffff;
        }
      }
    }
  }
}
.open-shopify-button{
  background: none;
  border: none;
  padding: 0;
}


.filter-group {
  display: flex;

  label {
    margin-right: 10%;
  }
}

.reportTable {

  .filterBar {
    background-color: #f0f3f9;
    padding: 13px 12px;
    margin-top: 13px;
    margin-bottom: 20px;
    border-radius: 5px;
  }

  .search-input-products {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  input#product-search {
    border-radius: 5px;
    font-weight: 400;
    font-size: 15px;
    line-height: 0px;
    color: #090909;
    padding: 10px;
    width: 30%;
    border: none;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.03) !important;
  }

  button#search-button {
    margin-left: 7px;
  }

  .products-list-view {
    flex-grow: 1;
  }

  .products-list-view:nth-of-type(odd) {
    background: rgb(255, 255, 255);
  }

  .products-list-view:nth-of-type(even) {
    background: #F8FAFF;
  }

  .list-item.headerItems {
    border-bottom: 1px solid #F0F3F9;
  }

  .item-column.nameItem {
    width: 220px !important;
    max-width: 220px !important;
    text-align: center !important;
    padding-left: 15px !important;
    text-align: left !important;
  }

  .item-column.idItem {
    width: 80px !important;
    max-width: 80px !important;
    text-align: center !important;
    padding-left: 15px !important;
  }

  .item-column.shopifyItem {
    width: 130px !important;
    max-width: 130px !important;
    text-align: center !important;
    padding-left: 15px !important;
  }

  .item-column.productItem {
    width: 150px !important;
    max-width: 150px !important;
    text-align: center !important;
    padding-left: 15px !important;
  }


  .item-column.elasticItem {
    width: 160px !important;
    max-width: 160px !important;
    text-align: center !important;
    padding-left: 15px !important;
  }

  .item-column.mirrorItem {
    width: 110px !important;
    max-width: 110px !important;
    text-align: center !important;
    padding-left: 15px !important;
  }

  .item-column.inShopifyItem {
    width: 120px !important;
    max-width: 120px !important;
    text-align: center !important;
    padding-left: 15px !important;
  }

  .item-column.onIndexItem {
    width: 110px !important;
    max-width: 110px !important;
    text-align: center !important;
    padding-left: 15px !important;
  }

  .item-column.syncItem {
    width: auto !important;
    min-width: min-content; 
    text-align: center !important;
    padding-left: 15px !important;
    padding-right: 15px!important;
  }

  .item-column.idItemRs {
    width: 150px !important;
    max-width: 150px !important;
    text-align: center !important;
    padding-left: 15px !important;
  }

  .item-column.catProdName {
    width: 350px !important;
    max-width: 350px !important;
    text-align: center !important;
    padding-left: 15px !important;
    text-align: left !important;
  }

  .item-column.catProdID {
    width: 350px !important;
    max-width: 350px !important;
    text-align: center !important;
    padding-left: 15px !important;
    text-align: left !important;
  }

  .item-column.catProdCatName {
    width: 350px !important;
    max-width: 350px !important;
    text-align: center !important;
    padding-left: 15px !important;
    text-align: left !important;
  }

  .item-column.catProdCatID {
    width: 350px !important;
    max-width: 350px !important;
    text-align: center !important;
    padding-left: 15px !important;
    text-align: left !important;
  }

  ul.pagination {
    margin-top: 60px;
  }

  .tab-content.active {
    width: 100%;
    min-width: min-content;
  }
  .spinner-div {
    margin-top: 80px;
    text-align: center;
    margin-bottom: 80px;
  }
}

.store-report-container .differences-div {
  margin: auto;
  padding-left: 9%;
  margin-top: 0;
}
.store-report-container .rightCol {
  border-left: 1px solid #c0c0c0!important; 
}

.no-products-message {
  margin: 30px 0 20px 0;
  font-weight: 500;
  text-align: center;
  color: #68778e;
  font-family: 'Source Sans Pro', sans-serif!important; 
}

.currently {
  margin: 30px 0 20px 0;
  font-weight: 500;
  text-align: center;
  color: #68778e;
  font-family: 'Source Sans Pro', sans-serif!important; 
}

.search-checkbox {
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: -6px;
  display: flex;

  input {
    margin: 5px 0 20px 20px;
  }

  p {
    margin-top: 5px;
    margin-left: 5px;
    font-family: $font;
    font-size: smaller;
    font-weight: 500;
    color: #68778e;
  }

  .search-check-title {
    font-weight: 800;
  }
}

.pagination {

  li.page-item a {
    justify-content: center;
    background-color: #F4F7FD;
    color: #138EFF !important;
    box-shadow: 0px 2px 4px 0px #838f9e54 !important;
    border: none !important;
    font-weight: 600;
    font-family: 'Source Sans Pro', sans-serif!important;

    &:hover {
      background-color: #138EFF!important;
      color: #ffffff !important;
    }
  }

  li.page-item.active a {
    background-color: #138EFF;
    color: #ffffff !important;
  }

}

.sync-error-retrieve {
  font-family: $font;
  color:#090909;
  font-weight: 600;
  text-align: center;
  margin: 10%;
}