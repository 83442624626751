.customization-tool-container { 
    .banner-landscape .topBar {
        background-color: #f6f0b7;
        height          : 17px;
    }
    
    .banner-landscape .titleHeader {
        padding         : 50px 50px 10px 50px;
        background-color: #fcfae9;
    }
    
    .banner-landscape h1.comming-soon {
        color      : #f6f0b7;
        text-align : center;
        margin     : 3rem;
        text-shadow: 0px 0px 25px black;
    }
    
    span.esl {
        margin-left: 15px;
    }
    
    .banner-landscape .container.up {
        max-width: none;
    
    }
}