.customization-tool-container { 
    .banner-footer .topBar {
        background-color: #c4b6ff;
        height          : 17px;
    }
    
    .banner-footer .titleHeader {
        padding         : 50px 50px 10px 50px;
        background-color: #ede9ff;
    }
    
    .banner-footer h1.comming-soon {
        color     : #c4b6ff;
        text-align: center;
        margin    : 3rem;
    }
    
    .banner-footer input.input-round.form-control {
        width: 100%;
    }
    
    .bigI {
        margin-top: -37px;
    }
    
    .bigI {
        height        : 80px !important;
        padding-bottom: 41px;
    }
}