$font: 'Source Sans Pro', sans-serif;

.bottomArea {

  .differences-info {
    display: flex;

    .compare-div,
    .differences-div {
      margin-right: 20px;

      .unavailable {
        background-color: #ccc;
        border: none;
        padding: 10px 27px;
        font-size: 12px;
        cursor: not-allowed;
        border-radius: 4px;
      }
    }
  }

  .more-info {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    p {
      font-weight: 500;
      color: #77889F;
    }

  }
}

.sync-results {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .listed-differences {
    flex: 0 0 calc(50% - 20%);
    display: flex;
    flex-direction: column;
    margin-right: 10%;

    .progress-title {
      font-family: $font;
      font-weight: bold;
      margin-top: -2px;
    }

    .differences-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      .column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 6%;

        &.shopify {

          .differences-column-title,
          .differences-column-result {
            color: #138EFF;
          }
        }

        &.system {

          .differences-column-title,
          .differences-column-result {
            color: #2CBC87;
          }
        }

        &.index {

          .differences-column-title,
          .differences-column-result {
            color: #914CF3;
          }
        }

        .differences-column-title {
          font-family: $font;
          font-weight: 700;
          font-size: 65%;
          line-height: 90%;
          text-transform: capitalize;
          margin-bottom: 3px;
          margin-top: -10%;
        }

        .differences-column-result {
          font-family: $font;
          font-size: 100%;
          line-height: 100%;
          margin-top: -5px;
          font-weight: 600;
          margin-bottom: -4px;
        }
      }
    }
  }

  .blueTitle {
    color: #138EFF;
    font-size: 11px !important;
    font-weight: 600 !important;
    line-height: 10px;
  }

  .blackTitle {
    font-size: 10px !important;
    font-style: normal;
    font-weight: 400 !important;
    color: rgba(0, 0, 0, 0.87) !important;
    line-height: 10px;
    margin-bottom: 4px !important;
    margin-top: -7% !important;
  }

  .rightCol {
    border-left: 1px solid rgba(189, 208, 234, 0.30);
    padding-left: 9px;
    margin-left: 2px;
  }

}

.error-auto-sync {
  font-weight: 500;
  cursor: pointer;
  font-family: "Source Sans Pro", sans-serif;
  font-size: smaller;
  margin-left: 89px;
  margin-top: 8px;
  position: absolute;
  z-index: 998;
}

.storeStatus {
  height: 17px;
  width: 17px;
  position: absolute;
  top: 3px;
  right: 4px;
  border-radius: 50%;
  border: 2px solid #fff;
  cursor: pointer;
  z-index: 2;

  &.storeActive {
    background-color: #3FD59E;
  }

  &.storeDevelopment {
    background-color: #FEDB21;
  }

  &.storeOff {
    background-color: #BDD0EA;
  }

  &:after {
    content: attr(data-tooltip);
    display: none;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px;
    background-color: #333;
    color: white;
    font-weight: 500;
    font-size: small;
    border-radius: 5px;
    width: max-content;
  }
  &:hover:after {
    display: block;
  }
}

.ribbon {
  position: absolute;
  left: 4px;
  z-index: 1;
  overflow: hidden;
  width: 80px;
  height: 107px;
  text-align: right;
  bottom: 4px;

  span {
    font-size: 8px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 103px;
    display: block;
    background: #1b77fd;
    position: absolute;
    left: -36px;
    bottom: 5px;

    &.v2 {
      background: #914cf3;
    }

    &.v2-beta {
      background: #2cbc87;
    }
  }
}

.sync-info-container {
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 75%;
  flex-direction: column;
  
  .user-info-div{    
    margin-right: 5%;
    align-items: center;

    .user-text{
      color: #77889F;
      font-weight: 500;
      margin-bottom: 0;      
    }

    .user-title{
      margin-right: 3px;
      margin-bottom: 0;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      text-transform: capitalize;
      color: #495462;
    }

  }

  .user-info-div.lapseRow {
    display: flex;
    gap: 2px;
    margin-top: -1px;
    align-items: center;

    p {
      margin-bottom: 0;
    }

}
.user-info-div.userRow {
  display: flex;
  margin-bottom: -1px;
}

}

.bottomArea.compare {
  padding-right: 13px;
}

.sync-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background: rgba(0, 0, 0, 0.3);

  .sync-modal {
    width: 90%;
    max-width: fit-content;
    max-height: 100%;
    overflow: auto;
    background: #fff;
    border-radius: 7px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    font-family: Arial, sans-serif;
    background: #FFF;
  }
}

.next-sync-div {
  display: flex;
  align-items: center;
  margin-top: 4px;

  p.title {
    margin-right: 3px;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    text-transform: capitalize;
    color: #495462;
  }
}

.error-sync {
  text-align: center;
  p{
    font-size: small;
    color: red;
    font-weight: 600;
    padding: 0;
    margin: 0;
  }
}

.error-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background: rgba(0, 0, 0, 0.3);

  .error-message-popup {
    width: 90%;
    max-width: fit-content;
    max-height: 100%;
    overflow: auto;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px;
    background: #FFF;
    p {
      margin: 20px;
      font-family: $font;
      color: black;
      font-weight: 600px;
      text-align: center;

    }
  
    button {
      margin-left: auto;
      background: #de1717;
      color: #fff;
      border-radius: 5px;
      border: none;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      transition: all .3s;
      box-shadow: 0px 2px 4px 0px rgba(189, 208, 234, 0.30);
      letter-spacing: 0.18px;
      min-height: 44px;

      &:hover {
        background: #b61515;
        background-repeat: no-repeat;
        background-position: 4% 50%;
      }
    }
  }
}


.card-enable-store {
  margin-top: 5px;

  label {
    display: flex;
    align-items: center;
    margin-right: 15px;
    font-size: 75%;
    gap: 12px;
    padding-right: 14px;
    color: #77889F;
    width: 200px;
    margin-top: 20px;
    margin-bottom: -15px;
  }

  input[type="checkbox"] {
    width: 30px;
    height: 15px;
    position: relative;
    cursor: pointer;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: #ccc;
      border-radius: 15px;
      position: absolute;
      transition: background-color 0.3s;
    }

    &:checked:before {
      background: #1B78CE;
    }

    &:after {
      content: "";
      display: block;
      width: 50%;
      height: 100%;
      background: #fff;
      border-radius: 50%;
      position: absolute;
      left: 0;
      transition: left 0.3s;
    }

    &:checked:after {
      left: 50%;
    }
  }
}


.openDeploySite {
  button {
    border: none;
    background: none;

    &::after {
      content: attr(data-tooltip);
      font-size: small;
      font-weight: 500;
      background-color: rgba(0, 0, 0, 0.8);
      color: #fff;
      padding: 5px;
      border-radius: 3px;
      position: absolute;
      bottom: 35%;
      left: 42%;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.2s;
      width: max-content;
      z-index: 1000;
    }
  
    &:hover::after {
      opacity: 1;
      visibility: visible;
    }
  }
}

a.scheduled-pending {
  font-size: 12px;

  &:hover {
    color: #77889F;
  }
}

.last-updated-div {
    display: flex;
    align-items: center;

    p {
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      text-transform: capitalize;
      color: #495462;
    }
}