@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.admin-portal-header-a svg path {
  fill: #fff;
}

.layout {
  display: flex;
  
}

main#mainContent {
  max-width: 1570px;
  width: 100%;
  padding: 15px;
  margin: auto;
  margin-top: 48px;
}

li {
  list-style: none;
}

ul {
  padding-left: 0 !important;
  margin-bottom: 0 !important;
}

/*------HEADER-------*/
button.logout {
  font-family: 'Source Sans Pro', sans-serif;
  background-color: #f65146;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  margin: 10px;
}

button.logout:hover {
  background-color: #d32f2f;
}

li.logout {
  display: flex;
  justify-content: right;
}

button.logout:focus {
  outline: none;
}

.searchContainer.col-xs-6.col-sm-.col-md-6 {
  padding-right: 55px;
}

.admin-portal-header-list {
  margin-bottom: 40px;
}

a.admin-portal-header-a svg {
  max-width: 160px;

}

.admin-portal-top h1 {
  font-size: 22px;
  color: #138efc;
  margin-top: 25px;
  margin-left: -9px;
  font-weight: 600;
}

.admin-portal-top {
  display: flex;
  margin-bottom: 30px;
  align-items: center;
}

.admin-portal-top img {
  margin-right: 10px;
  margin-top: 10px;
  width: 160px;
  padding: 5px;
}

.headerWrapper {
  background-color: #208eff;
}

.headerContainer {
  max-width: 1570px;
  padding: 0px 15px 0px 15px;
  margin: auto;

}

.actionsBar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.searchContainer {
  text-align: center;
}

input.admin-portal-search-input {
  border-radius: 5px;
  font-weight: 400;
  font-size: 15px;
  line-height: 0px;
  color: #090909;
  padding: 15px;
  width: 100%;
  border: none;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.03) !important;
  background: #fff url(../assets/search.png) no-repeat;
  background-position-x: 97%;
  background-position-y: 47%;
}

input.admin-portal-search-input::placeholder {
  color: #959595;
  font-style: italic
}

input:focus-visible {
  outline: none;
}

.bottomBar {
  display: grid;
  grid-template-columns: 66% 16% 9% 9%;
}

.newStore-container button {
  border-radius: 5px;
  background: #138EFF;
  border: none;
  color: #fff;
  padding: 4% 10%;
  font-weight: bold;
  font-size: 85%;
  transition: all .3s;
  margin-left: 17px;
  height: 48px;
  width: max-content;
  display: flex;
  gap: 10px;
  align-items: center;
}

.newStore-container button:hover {
  background: #1284ee;
}

.newStore-container button img {
  margin-right: 2px;
  margin-top: -3px;
}

button.refresh-button svg {
  margin: 0;
}

li.admin-portal-header-li.col-6 {
  display: flex;
}

.last-refresh-time-container {
  margin-left: 30px;
}

.admin-portal-header h1 {
  font-size: 17px;
  padding: 27px 0 0 7px;
  color: #717171;
  font-weight: 300;
}

.col-10.last-refresh-time-div {
  margin-left: -3px;
}

.MuiPopover-paper .MuiButtonBase-root svg {
  color: #138EFF !important;
  margin-right: 6px;
  max-width: 22px;
}

span.admin-portal-date-utc-span {
  min-width: 177px;
}

ul.MuiList-root.MuiList-padding.MuiMenu-list {
  font-size: 14px;
}

/*----- CARD------*/
div#root {
  background: #f0f3f9;
  padding: 0px 0 0 0;
}

.basic-dates-store.topArea.row {}

.btnRechargue button {
  background: #FFFFFF;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
  line-height: 0px;
  color: #138EFF;
  padding: 13px 43px 11px 43px;
  border: none;
  transition: all .5s;
}

.btnRechargue button:hover {
  background: #F4F7FD;
}

.btnRechargue svg {
  margin-right: 7px;
  margin-top: -3px;
  width: 20px;
}

.row.store-sections-cards {
  gap: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 15px auto;
}

.cardContainer {
  border: none;
  background: #FFFFFF;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.03) !important;
  border-radius: 10px;
  max-width: 550px !important;
  padding: 20px 20px !important;
}

.topArea {
  display: grid;
  grid-template-columns: repeat(4, auto);
  align-items: center;
  position: relative;
}

.storeLogo {
  max-width: 83px;
  min-width: 83px;
  min-height: 80px;
  padding: 4px;
  height: auto;
  border-radius: 10px;
  background: #f5f7fb;
  position: relative;
}

.storeLogo img {
  max-width: 75px;
  min-height: 74px;
  height: 100%;
  border-radius: 10px;
  border: 1px solid #BDD0EA;
  object-fit: contain;
  background-color: white;
  padding-left: 4px;
  padding-right: 3px;
}

.storeName {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
}

.storeName h3 {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 0;
  min-height: 38px;
  word-break: break-word;
}

.storeName svg {
  margin-top: -12px;
}

.store-date .col-10.last-refresh-time-div {
  width: 90%;
  margin-bottom: -2px;
}

span.store-plan-span svg {
  margin-top: -3px;
  width: 13px;
}

.management-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 0;
  z-index: 1;
}

.title-type {
  z-index: 2;
  margin-bottom: 0;
}

.cardContainer .management-div {
  top: 0;
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium:hover {
  background-color: transparent;
}

.websiteContainer {
  display: flex;
  align-items: center;
}

.websiteContainer a {
  text-decoration: none;
  font-weight: 400;
  font-size: 13px;
}

.col-s10.datesStoreContainer.col-4 {
  width: 38%;
  padding-left: 28px;
}

.websiteContainer img {
  margin-top: -15px;
  margin-right: 3px;
}

.uptdate p {
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 0 !important;
  margin-top: 8px;
}

.uptdate .one-col {
  display: grid;
  grid-template-columns: 14% auto;
}

.uptdate span {
  font-weight: 400;
  display: block;
}

.centerArea {
  background-color: #F5F7FB;
  border-radius: 10px;
  padding: 10px 10px 10px 9px;
}

.bottomArea {
  background-color: #F5F7FB;
  border-radius: 10px;
  padding: 15px 10px 18px 15px;
  margin-top: 15px;
  display: grid;
  grid-template-columns: 50% 20% 20%;
  width: 100%;
  gap: 0 18px;
}

.bottomArea.compare {
  grid-template-columns: 63% auto;
  gap: 0 18px;
  padding-bottom: 15px;
}

.store-date div.compare {
  float: left;
  margin: 20px 0 0 0;
}

.store-error-section p {
  padding: 10px;
  font-size: 13px;
  color: #ff3d08;
  font-weight: 600;
  background-color: #F9C1B1;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFHSURBVHgBtVXtTcMwFLwXFugIHQSkdIRuABuwQcUEiAloN2ADwof4C2zQEVjANee0bhL56yVRT0rivMS+u+dnW6CErbGEwTebf3yu5Qs/mn4VtDB45n3Ba4krPGq7qQiovuaj7oVqez14n0dA9ZsgJpHYFIKIeg+Vi7IDk1GqcJElCNQLnnjfwVXSEUUXeQdD9Vt5x7184PZE5Ek3kwgiuX/rPmLfi2ddpB0YXZW0yLiIEmQqJ4Wki7iDMeo9Ei4CggnqPaIuQgdT1HtEXAwIRqhfJOKBCxkQ3OA1SSDcnius4RbZgdu25a4aR8O1sgoIWvWmJZgPi5V8onHNLkW63LvF9lL8qzcXLYEy91tar3m5NO0K/57n4uhAp/631y4flycXMiL3ex6Vd3AVdOCRmZ7kDm4uspUzH40jsLggKg7/gEuBY/8DjJljslbnppMAAAAASUVORK5CYII=) no-repeat;
  background-position-x: 7px;
  background-position-y: 96%;
  padding-left: 38px;
  padding-top: 20px;
  margin-bottom: 0;
  padding-bottom: 0;
  margin-left: -6px;
}

p.store-error-message:empty {
  padding-top: 35px;
}

.errorArea {
  grid-column: 1/4;
}

p.status {
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  text-transform: capitalize;
  color: #495462;
  margin-bottom: 0px;
}

span.last-time-refresh-status {
  font-weight: 400;
}

span.status-idle,
span.status-synchronising {
  padding-left: 4px;
}

.status span {
  font-weight: 400;
}

.syncBtn button,
.syncBtn div {
  max-width: 160px;
  min-height: 35px;
  background-color: #fff;
  border: 1px solid #045c7c;
  color: #045c7c;
  font-weight: 600;
  font-size: 12px;
  box-shadow: 0px 2px 4px rgba(189, 208, 234, 0.3);
  border-radius: 5px;
  padding: 5px 11px 7px 11px;
  margin-top: 7px;
  margin-right: 5px;
}

.syncMenuBtn button {
  max-width: 160px;
  min-height: 35px;
  background-color: #fff;
  border: 1px solid #67C6E8;
  color: #67C6E8;
  font-weight: 600;
  font-size: 12px;
  box-shadow: 0px 2px 4px rgba(189, 208, 234, 0.3);
  border-radius: 5px;
  padding: 5px 11px 7px 11px;
  margin-top: 7px;
  margin-right: 5px;
}

.sync-button-container{
  display: flex;
}

.syncBtn button:hover {
  background-color: rgb(122, 166, 183);
  border: 1px solid rgb(122, 166, 183);
  color: #fff;
}

.syncMenuBtn button:hover {
  background-color: #045c7c;
  border: 1px solid #045c7c;
  color: #fff;
}

.syncBtn .div-button-synchronising {
  border: 1px solid #045c7c !important;
  color: #045c7c;
}

.syncBtn img {
  margin-top: -2px;
  margin-right: 6px;
}

span.spinner-border.spinner-border-sm {
  margin-top: 2px;
  margin-right: 6px;
  margin-left: -2px;
  vertical-align: middle;
}

span.date {
  display: flex;
  gap: 8px;
  margin-top: 3px;
}

span.utc {
  color: #77889f;
  font-size: 12px;
}

span.status-synchronising {
  color: #1A903C;
  font-weight: 500;
}

p.progress-title.diferences {
  font-weight: 500;
  color: #138EFF !important;
  margin-top: 1px;
}

p.not-available {
  font-weight: 600;
  color: #138EFF !important;
  margin-bottom: 0;
  margin-top: 10px;
}

img.last-refresh-time-img.col-4 {
  max-width: 100px;
}

p.col-8.last-refresh-time-p {
  font-size: 16px;
  vertical-align: center;
  align-items: center;
  display: flex;
  font-weight: 500;
  color: black;
}

p.last-time-refresh {
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  text-transform: capitalize;
  color: #77889F;
  margin-bottom: 10px;
}

span.status-idle {
  color: #999;
}

img.admin-portal-header-img {
  max-width: 250px;
  max-height: 100px;
}

p.admin-portal-date-utc-p {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding-top: 16px;
}

.div-button-synchronising {
  display: flex;
  align-items: center;
}

.last-refresh-time-div.row {
  border-radius: 5px;   
    gap: 10px;
    margin-bottom: 0px;   
}
.basic-dates-store.topArea.row {
  align-items: flex-start;
}
.col-10.last-refresh-time-div {
  width: 78%;
}
p.title-type {
  margin-top: -7px;
}
p.last-refresh-time-div {
  margin: auto;
  font-size: 13px;
  font-weight: 500;
  margin: 0 0 -8px 0;
}

.store-date p.last-refresh-time-div {
  margin-right: 3px;
  margin-bottom: -9;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  text-transform: capitalize;
  color: #495462;
}

.store-date span.last-refresh-time-span {
  color: #77889F;
}

.last-refresh-time-container-small.col-6 {
  width: 44%;
  margin-bottom: -1px;
  margin-top: 30px;
  background-color: #f5f7fb;
  border-radius: 10px;
  padding: 10px 10px 18px 15px;
}

span.last-refresh-time-span {
  font-size: 11px;
  font-weight: 500;
}

.management-div .MuiButtonBase-root svg {
  margin-right: 8px;
}

span.last-refresh-time-svg.col-2 {
  margin: -1px -10px 0 auto;
  background-color: #fff;
  border-radius: 5px;
  height: 47px;
  padding-top: 9px;
  width: 46px;
}

.button-progress {
  display: grid;
  gap: 9px;
  grid-template-columns: 72% 28%;
}

.button-progress button {
  background-color: #67C6E8;
  color: #fff;
  border: none;
  font-weight: 600;
  font-size: 12px;
  box-shadow: 0px 2px 4px rgba(189, 208, 234, 0.3);
  border-radius: 5px;
  padding: 6px 8px 7px 8px;
}

.button-progress button:hover {
  background-color: #045c7c;
}

.store-date button.MuiButtonBase-root {
  padding: 0;
  margin-right: -8px;
}

span.data-progress {
  font-size: 22px;
  font-weight: 600;
}

p.progress-title {
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  text-transform: capitalize;
  color: #495462;
  margin-bottom: 7px;
  margin-top: -1px;
}

.container-progress.col-6 {
  padding-left: 20px;
}

/*------------REPORTE------------*/
.store-report-container {}

.store-report-container .last-refresh-time-div.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

.store-report-container .last-refresh-time .last-refresh-time-svg {
  width: 45px !important;
  height: 45px !important;
  justify-content: center;
}

.store-report-container span.last-refresh-time-span {
  font-size: 13px;
  font-weight: 500;
  margin-top: 5px;
  min-width: max-content;
  font-family: "Source Sans Pro", sans-serif;
}

.div-tabs button.button.active,
.div-tabs button.button.inactive {
  font-weight: 600 !important;
  font-size: 14px;
  border-radius: 5px 5px 0 0;
}
p.comingSoon {
  font-family: "Source Sans Pro", sans-serif;
  color: #090909;
  font-weight: 600;
  text-align: center;
  margin: 10%;
}

.div-tabs .button {
  min-width: 150px;
}

.div-tabs .sync-btn {
  background-color: #ffffff;
  padding-top: 4px;
  padding-bottom: 4px;
  border-color: #ABBDD6;
}

.div-tabs .MuiTableCell-body {
  line-height: 1;
  font-family: "Source Sans Pro", sans-serif;
  border: none;
}

.MuiTableRow-head span,
.MuiTableRow-head th {
  font-weight: 600 !important;
  font-family: "Source Sans Pro", sans-serif;
}

.tab-content.active {
  padding-top: 10px !important;
}

.MuiTableRow-root td {
  color: #4F627C;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
}

th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-paddingNone.MuiTableCell-sizeMedium {
  padding-left: 14px;
}

.containerPlan {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  gap: 10px;
  margin-bottom: -6px;
}

h1.report-title {
  font-weight: 600 !important;
  font-size: 25px !important;
}

.store-info {
  padding-top: 20px;
  padding-bottom: 30px;
  align-items: flex-end !important;
}

table tbody tr:nth-child(odd) {
  background: #F4F7FD;
}

table tbody tr:nth-child(even) {
  background: #fff;
}

.MuiTablePagination-toolbar .MuiTablePagination-selectLabel,
.MuiTablePagination-toolbar .MuiTablePagination-displayedRows {
  margin-bottom: 3px;
  color: #52637d;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
}

.MuiTablePagination-toolbar {
  min-height: 80px !important;
}

/*------------FILTROS------------*/
.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
  font-size: 13px !important;
  color: #77889F !important;
}


/*------------REPORT------------*/

h1.report.title {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
}

p.text-differences {
  font-size: 15px;
  font-weight: 600;
  color: #138EFF;
}

p.text-tab {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-transform: capitalize;
  margin-bottom: 7px;
  margin-top: -1px;
  color: #000000;
}


.store-report-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.store-info {
  display: flex;
  align-items: center;
}

.last-refresh-time {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.product-sync {
  align-items: center;
}

.sync-checkbox {
  margin-left: 7px;
}

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.button-navigation {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  outline: none;
}

.middle-navigation {
  background-color: #f0f0f0;
  color: #333;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  outline: none;
}

.middle-navigation:hover {
  background-color: #ddd;
}

.navigation-info {
  text-align: center;
  margin-top: 10px;
}

.navigation-text {
  font-size: 12px;
  color: #666;
}

.button-navigation:disabled,
.middle-navigation:disabled {
  background-color: #ddd;
  color: #999;
  cursor: not-allowed;
}

.button-navigation:hover:not(:disabled),
.middle-navigation:hover:not(:disabled) {
  background-color: #ddd;
}

.btn-seeMore {
  border: 1px solid #CBD8EA;
  background-color: #E4EBF6;
  color: #77889F;
  padding: 0px 6px 2px 6px;
  border-radius: 6px;
  font-family: 'Source Sans Pro', sans-serif;
  transition: all .3s;
  margin-top: 8px;
  margin-bottom: -3px;
}

.btn-seeMore:hover {
  background-color: #f6f6f6;
}

button.btn-seeMore svg {
  width: 12px;
}

.unavailable {
  cursor: not-allowed;
  border-radius: 5px;
}

.store-list-filter span.MuiButton-startIcon svg path {
  stroke: #208eff;
}

.store-list-filter span.MuiButton-startIcon svg {
  width: 12px;
  margin-left: 10px;
}

.btnRechargue .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  color: #ffffff !important;
}

button.btn-seeMore.progr svg {
  transform: rotate(270deg);
  margin-top: -2px;
}

.css-2xinfk-MuiButtonBase-root-MuiButton-root:active {
  font-size: 13px !important;
  background-color: transparent !important;
}

.MuiButton-textSecondary,
.MuiButtonBase-root.MuiButton-root.MuiButton-text {
  font-size: 13px !important;
  background-color: transparent !important;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  color: #208eff !important;
}

/*
li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected { 
  font-size: 15px!important;
  color: #fff!important;
}
.filter-stores-plan .MuiPaper-elevation {
  background-color: #000!important;
  color: #fff!important;
  border-radius: 6px!important;  
}
*/
/*.MuiMenu-list:before {
    content: url(../assets/refresh.svg);
    position: absolute;
    left:-20px;
    top: 0px;
}*/

/*------------ EDIT STORE ------------*/
.editStores {
  margin-top: -15px;
}

.editStores .MuiBox-root {
  background-color: #f0f3f9;
}

.leftVertical {
  background-color: #fff;
  /* padding-top: 80px; */
  min-width: 275px;
  min-height: 90vw;
}

.leftVertical .title-site {
  margin-left: 10px;
}

.editStores .sync-management {
  background-color: #f0f3f9;
}

.BackTab {
  align-items: flex-start !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  color: #218eff !important;
  font-size: 16px !important;
  font-size: 16px !important;
  width: 85%;
  border-radius: 5px !important;
  background: url(https://cdn.shopify.com/s/files/1/0622/8577/5024/files/Vector_5arrow_back.png?v=1696947840) no-repeat;
  padding-left: 22px !important;
  background-position: 18px center;
  text-transform: initial !important;
  background-position-x: 0;
  margin: 20px 0px 2px 0 !important;

}

.title-site {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  max-height: 78px;
  margin-right: 10px;
}

.logo-title-store {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 10px !important;
  margin-top: 10px !important;
  margin-bottom: 40px !important;


  .logo-store-edit-section {
    max-width: 75px;
    min-height: 74px;
    height: 100%;
    border-radius: 10px;
    border: 1px solid #BDD0EA;
    /* object-fit: contain;  */
    background-color: white;
    margin-right: 10px;

  }

  h6 {
    margin: 0;
  }
}

.SyncTab {
  align-items: flex-start !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  color: #000 !important;
  font-size: 16px !important;
  font-size: 16px !important;
  width: 85%;
  border-radius: 5px !important;
  background: url(https://cdn.shopify.com/s/files/1/0622/8577/5024/files/sync.png?v=1696262901) no-repeat;
  padding-left: 50px !important;
  background-position: 18px center;
  text-transform: initial !important;
}

.SyncTypeTab {
  align-items: flex-start !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  color: #000 !important;
  font-size: 16px !important;
  font-size: 16px !important;
  width: 85%;
  border-radius: 5px !important;
  background: url(https://cdn.shopify.com/s/files/1/0622/8577/5024/files/gear.svg?v=1718373064) no-repeat;
  padding-left: 50px !important;
  background-position: 15px center;
  background-size: 25px;
  text-transform: initial !important;
}

.RetailSystemTab {
  align-items: flex-start !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  color: #000 !important;
  font-size: 16px !important;
  font-size: 16px !important;
  width: 85%;
  border-radius: 5px !important;
  background: url(https://cdn.shopify.com/s/files/1/0622/8577/5024/files/retail.png?v=1696262901) no-repeat;
  padding-left: 50px !important;
  background-position: 18px center;
  text-transform: initial !important;
}

.ShopifyTab {
  align-items: flex-start !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  color: #000 !important;
  font-size: 16px !important;
  font-size: 16px !important;
  width: 85%;
  border-radius: 5px !important;
  background: url(https://cdn.shopify.com/s/files/1/0622/8577/5024/files/shopy.png?v=1696262901) no-repeat;
  padding-left: 50px !important;
  background-position: 18px center;
  text-transform: initial !important;
}

.HydrogenTab {
  align-items: flex-start !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  color: #000 !important;
  font-size: 16px !important;
  font-size: 16px !important;
  width: 85%;
  border-radius: 5px !important;
  background: url(https://cdn.shopify.com/s/files/1/0622/8577/5024/files/hidro.png?v=1696262901) no-repeat;
  padding-left: 50px !important;
  background-position: 18px center;
  text-transform: initial !important;
}

.SyncAppTab {
  align-items: flex-start !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  color: #000 !important;
  font-size: 16px !important;
  font-size: 16px !important;
  width: 85%;
  border-radius: 5px !important;
  background: url(https://cdn.shopify.com/s/files/1/0622/8577/5024/files/app.png?v=1696262901) no-repeat;
  padding-left: 50px !important;
  background-position: 18px center;
  text-transform: initial !important;
}

.CSVTab {
  align-items: flex-start !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  color: #000 !important;
  font-size: 16px !important;
  font-size: 16px !important;
  width: 85%;
  border-radius: 5px !important;
  background: url('../assets/CSV-file.png') no-repeat;
  padding-left: 50px !important;
  background-position: 18px center;
  text-transform: initial !important;
}

.SettingsTab {
  align-items: flex-start !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  color: #000 !important;
  font-size: 16px !important;
  font-size: 16px !important;
  width: 85%;
  border-radius: 5px !important;
  background: url('../assets/BracketsIcon.png') no-repeat;
  padding-left: 50px !important;
  background-position: 18px center;
  text-transform: initial !important;
}

.CustomizationToolTab {
  align-items: flex-start !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  color: #000 !important;
  font-size: 16px !important;
  font-size: 16px !important;
  width: 85%;
  border-radius: 5px !important;
  background: url('../assets/layout.png') no-repeat;
  padding-left: 50px !important;
  background-position: 18px center;
  text-transform: initial !important;
}

.SyncTab.Mui-selected,
.RetailSystemTab.Mui-selected,
.ShopifyTab.Mui-selected,
.HydrogenTab.Mui-selected,
.SyncAppTab.Mui-selected {
  background-color: #F0F3F9;
}

.leftVertical .MuiTabs-flexContainerVertical {
  display: flex;
  align-items: center;
}

.dataPanel .sync-header p {
  color: #77889F !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  font-family: 'Source Sans Pro', sans-serif !important;
}

.dataPanel .sync-management .sync-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 10px;
  flex-direction: column;
}

.sync-management .sync-header h1 {
  font-weight: 600;
}

.sync-management .divider {
  background-color: #BDD0EA;
}

.sync-management .sync-header h1 img {
  height: auto !important;
  width: auto !important;
  margin-top: 2px;
}

.dataPanel {
  padding-top: 32px;
  padding-left: 80px;
}

.sync-management .field .field-label {
  font-size: 14px !important;
  font-weight: 600 !important;
  font-family: 'Source Sans Pro', sans-serif !important;
}

.sync-management .field .field-box,
.sync-management .field .field-form,
.sync-management .field .field-input,
.sync-management .field .field-edit {
  background-color: #ffffff !important;
  font-size: 16px !important;
  color: #77889F !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  min-height: 50px;
}

.MuiInputBase-input {
  background-color: #ffffff !important;
  font-size: 16px !important;
  color: #77889F !important;
  font-family: 'Source Sans Pro', sans-serif !important;

}

.MuiFormControl-root.MuiTextField-root,
.sync-management .field .field-edit button {
  background-color: white;
}

#syncSecret,
#syncToken,
.field-edit input {
  font-size: 16px !important;
  color: #77889F !important;
  font-family: 'Source Sans Pro', sans-serif !important;
}

.sync-management .specific-field .image-input-container .logo-image {
  max-height: 60px !important;
  width: auto !important;
  filter: none !important;
  border-radius: 0 !important;
  max-width: 200px;
  object-fit: contain;
}

.sync-management .specific-field.logo {
  display: flex;
  align-items: flex-start !important;
}


.form-container {
  gap: 15px !important;
  justify-content: center !important;

  input {
    margin: 0;
    padding: 15px;
    border: 1px solid #BDD0EA;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    color: #ABBDD6;
    font-size: 11px;
    /*min-width: 98%;*/
    border-radius: 6px;
    -o-border-radius: 6px;
    -ms-border-radius: 6px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;


  }

}

img.Rslogo {
  padding-right: 9px;
}

h5.shopifyName {
  font-size: 14px;
}
.dataPanel button.edit-button.save {
  margin-left: 15px;
}


@media (max-width: 700px) {

  .sync-management .form-container {
    flex-direction: column;

    input {
      min-width: 83%;

    }
  }
}




/*======================================== RESPONSIVE ========================================*/
/*--DESKTOP--*/

@media (min-width: 1200px) {

  main#mainContent:has(.editStores) {
    max-width: 1920px;
    padding: 15px;
    margin: auto;
    padding-left: 0 !important;
    margin-left: 0;
  }

  .editStores .dataPanel {
    padding-top: 32px;
    padding-left: 0;
    margin: 0 auto;
  }

  .headerContainer {
    max-width: 1915px;
    padding: 0px 40px 0px 15px;
  }

}


@media (max-width: 1660px) {


  .header-date-container {
    padding-right: 23px;
  }

  .btnRechargue button {
    float: right;
    margin-right: 8px;
  }

  .actionsBar .bottomBar {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
    padding-right: 20px;
  }

  .newStore-container button {
    float: right;
  }

}

@media (max-width: 1550px) {

  .row.store-sections-cards {
    display: flex;
    justify-content: center;
  }


}

@media (max-width: 1290px) {
  .bottomBar {
    grid-template-columns: auto auto auto;
  }


}



/*--TABLET--*/

@media (max-width: 1200px) {

  .store-sections-cards .col-md-6 {
    width: 48% !important;
  }

  .cardContainer {
    width: 48% !important;
  }

  .topArea {
    grid-template-columns: 95px auto;
  }

  .uptdate .one-col {
    grid-template-columns: 68px auto;
  }

  .bottomBar {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .last-refresh-time-div.row {
    min-width: 240px;
  }

  input.admin-portal-search-input {
    padding-left: 16px !important;
    padding-right: 16px !important;
    background-position-x: 94%;
  }

  .sync-management .form-container {
    flex-direction: column;
  }

  .dataPanel .sync-management {
    width: auto;
  }

  .editStores .dataPanel {
    width: 100%;
  }

  .editStores .MuiBox-root {
    flex-direction: column;
  }

  .leftVertical {
    background-color: #fff;
    padding-top: 32px;
    min-width: 275px;
    min-height: auto;
    padding-bottom: 20px;
  }

  .dataPanel {
    padding-top: 0;
    padding-left: 0;
  }

  .dataPanel .sync-management {
    padding: 0;
  }

  .dataPanel .MuiBox-root {
    padding: 40px 10px !important;
  }

  .sync-management .form-container {
    padding: 0 !important;
    gap: 0px !important;
  }

  .sync-management .lock-check {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    flex-direction: column;
    align-content: center;
    margin-bottom: 15px;
  }

  .leftVertical .logo-title-store {
    justify-content: center;
  }

  .slider-menu-overlay .slider-menu {
    width: 45%;
  }

  .sync-management.newStore .form-container .column {
    align-items: center;
  }
}









/*--MOBILE--*/

@media (max-width: 1040px) {

  div#root {
    overflow: hidden;
  }

  .bottomBar {
    display: flex;
    flex-direction: column;
  }

  .cardContainer {
    width: 100% !important;
  }

  .row.store-sections-cards {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .searchContainer {
    width: 100%;
    max-width: 550px !important;
  }

  .store-list-filter {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }

  .store-list-filter .order-by-sync {
    margin-left: -48%;
  }

  .store-report-container .store-info {
    display: grid;
    grid-template-columns: 1fr;
  }

  .store-report-container .store-info .store-details {
    margin-left: 0 !important;
    margin-top: 10px !important;
  }

  .store-report-container .div-tabs {
    width: 100%;
    overflow: auto;
  }

  .div-tabs .tabs {
    width: max-content;
  }

  .store-report-container .div-content {
    width: max-content;
  }

  .store-report-container .last-refresh-time {
    margin-left: 0 !important;
    justify-content: flex-start !important;
    align-items: center !important;
  }

  .store-report-container .store-info .storeLogo {
    margin-top: -20px !important;
  }

  .div-content .div-tabs {
    overflow: auto !important;
  }

  .div-content .products {
    overflow: initial !important;
    width: max-content !important;
    padding-right: 30px;
  }

  .store-report-container .last-refresh-time {
    display: inherit !important;
  }

  .store-report-container .last-refresh-time-div.row {
    max-width: 226px !important;
    margin-top: 3px;
    min-width: auto;
  }

  .div-tabs {
    overflow: auto !important;
  }

}







/*--SMALL--*/

@media (max-width: 775px) {
  .store-list-filter {
    grid-template-columns: 1fr 1fr;
  }

  .store-list-filter .order-by-sync {
    margin-left: auto;
  }

  .store-list-filter .order-by-sync {
    margin-left: auto;
    text-align: center;
    margin-bottom: 15px;
  }

  .order-by-name {
    margin-left: -11px;
  }

  .filter-stores-plan,
  .filter-stores {
    margin-bottom: 8px;
  }

  .admin-portal-header {
    flex-direction: column;
  }

  .header-date {
    text-align: center;
  }

  .admin-portal-top {
    justify-content: center;
  }

  .store-list-filter .order-by-sync {
    margin-right: auto !important;
  }

  .basic-dates-store.topArea.row {
    display: grid;
    grid-template-columns: 27% 85%;
  }

  .last-refresh-time-container-small.col-6 {
    grid-column: 1/4 !important;
  }

  .col-s10.datesStoreContainer.col-4 {
    width: 100%;
  }

  .bottomArea {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }

  .more-info {
    grid-column: 1/4;
  }

  .bottomArea .more-info {
    align-items: center !important;
  }

  .store-date div.compare {
    gap: 15px;
  }

  .store-error-section p {
    background-position-x: 4px;
    background-position-y: 74%;

  }

  .order-by-sync,
  .order-by-name {
    grid-column: 1/3;
  }

  .button-progress {
    display: grid;
    gap: 9px;
    grid-template-columns: 100%;
    justify-content: center;
    justify-items: center;
  }

  .differences-info {
    grid-column: 1/4;
  }

  .differences-info {
    justify-content: space-between;
  }

  .header-date {
    width: 100%;
    text-align: center;
    border-top: 1px solid #ffffff61;
    margin-top: 17px;
    text-align: center;
  }

  span.admin-portal-date-utc-span {
    margin-left: 0;
    padding: 0;
  }

  p.admin-portal-date-utc-p {
    padding-top: 10px;
    margin-bottom: 10px;
  }

  .cardContainer .bottomArea {
    display: flex;
    flex-direction: column;
    padding: 15px 10px 4px 15px;
  }

  .cardContainer .leftArea,
  .cardContainer .container-progress {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .cardContainer .more-info {
    border-top: 1px solid #c6c6c68c;
    padding-top: 5px;
    flex-direction: row !important;
    justify-content: flex-end !important;
  }

  .cardContainer p.progress-title {
    margin-bottom: 3px;
    margin-top: 12px;
    margin-right: 11px;
  }

  .cardContainer .bottomArea.compare {
    padding: 15px 10px 15px 15px;
  }

  .cardContainer .bottomArea .differences-info {
    flex-direction: column;
  }

  .cardContainer .bottomArea .differences-info .compare-div {
    margin-right: 0px !important;
  }

  .cardContainer .compare-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .cardContainer .differences-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 0 !important;
    margin-top: 10px;
  }

  .header-date-container {
    padding-right: 0;
  }

  .store-section-list {
    margin-top: 4% !important;
  }

  .filterBar {
    grid-template-columns: 1fr !important;
    justify-items: stretch !important;
    gap: 25px !important;
  }

  .header-date-container {
    padding-left: 10px;
  }

  .actionsBar .bottomBar {
    grid-template-columns: 1fr;
    justify-content: center;
  }
  .newStore-container {
    margin-top: -101px;
  }
  .slider-menu-overlay .slider-menu {
    width: 79%;
  } 
  .slider-menu.lateral {    
    padding: 4%!important;
  }
  .sync-results { 
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 15px;
  }
  .store-info .sync-results .rightCol {
    border-left: none!important;
    padding-left: 0!important;
    margin-left: 0!important;
  }
  .cardContainer .differences-div {    
    margin-top: 20px;
  }
  .actionsBar .bottomBar {   
    margin-bottom: -20px;
  }
}