.customization-tool-container {
    .header {
        margin-bottom: 0px;
        border-radius: 15px;
    
        .col {
            padding: 1em 0;
            cursor: pointer;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 500;
            color: #416267;
            position: relative;
            overflow: hidden;
    
            &:has(.ribbon) {
                padding-left: 8px;
            }
    
            &:hover {
                filter: brightness(1.1);
            }
    
            &.welcome {
                background-color: #07BB7A;
                color: #FFF;
                border-radius: 15px 0 0 0;
            }
    
            &.top-slider {
                background-color: #84F8CE;
            }
    
            &.central-categories {
                background-color: #fdd4ad;
            }
    
            &.values {
                background-color: #fcb4cf;
            }
    
            &.categories {
                background-color: #7dc4e2;
            }
    
            &.banner-landscape {
                background-color: #f6f0b7;
            }
    
            &.banner-footer {
                background-color: #c4b6ff;
                border-radius: 0 15px 0 0;
            }

            &.tagline {
                background-color: #e99c9c;
            }
    
            .ribbon-customization {
    
                span {
                    font-size: 8px;
                    color: #fff;
                    text-transform: uppercase;
                    text-align: center;
                    font-weight: bold;
                    line-height: 20px;
                    transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                    width: 103px;
                    display: block;
                    background: #655a7c;
                    position: absolute;
                    left: -36px;
                    bottom: 5px;
                }
            }
        }
    }
}
